/* ---------------------------------------

[Master StyleSheet]

Project:    	Eventrox - Digital Conference React Template
Version:    	1.0
Last Change:    30/12/2023

------------------------------------------

[Table of contents]

1. Fonts
2. Resources / Css Files
3. Reset
4. Global Settings
5. Buttons Style
6. Social Icons
7. Scroll To Top style
8. Page Title
9. Section Title
10. Main Header / Two / Three
11. Mobile Menu
12. Banner Section
13. Banner Conference
14. Banner MeetUp
15. About Section / Two
16. Coming Soon
17. Features Section / Two / Three
18. Fluid Section One
19. Speakers Section / Two / Three
20. Speaker Detail
21. schedule Section / Two / Three
22. Event Detail
23. Pricing Section / Two / Three
24. Fun Fact Section
25. FAQ's Section
26. FAQ Form Section
27. Fun Fact Section Two
28. Why Choose Us
29. App Section
30. Gallery Section
31. Event Info Section
32. Testimonial Section
33. News Section
34. Blog Sidebar
35. Blog Detail
36. Styled Pagination
37. Coming Soon
38. Video Section
39. Call to Action
40. Register Section
41. Contact Page Section
42. Map Section
43. Subscribe Section
44. Newsletter Section
45. Clients Section / Two / Three
46. Main Footer / Two / Three
47. Error Section
48. Sidebar Page Container 
49. Comment Area
50. Comment Form
51. Social icon Colored
52. Right to Left
53. Boxed Layout
-------------------------------------------*/

/*** Fonts ***/

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900|Orbitron:500,700|Ubuntu:400,500,700&display=swap');

/*** Resources / Css Files ***/

@import url('fontawesome-all.css');
@import url('flaticon.css');
@import url('elegent-icon.css');
@import url('animate.css');

/*** Reset ***/

* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
}

/*** ========= Global Settings ========= ***/

body {
	font-size: 14px;
	color: #fff;
	line-height: 1.7em;
	font-weight: 400;
	background: #ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-family: 'Poppins', sans-serif;
}
.background-video {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
 }
.welcome-text {
    padding-bottom: 180px;
    padding-top: 180px;

}
.video-content{
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}
video{
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

a {
	text-decoration: none;
	cursor: pointer;
	color: #8c7c20;
}

button,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	/* font-weight: normal; */
	margin: 0px;
	background: none;
	/* line-height: 1.6em; */
	font-family: 'Ubuntu', sans-serif;
}

textarea {
	overflow: hidden;
}

p,
.text {
	position: relative;
	font-size: 16px;
	line-height: 26px;
	color: #888888;
	font-weight: 400;
}

.auto-container {
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}

.large-container {
	position: static;
	max-width: 1550px;
	padding: 0px 15px;
	margin: 0 auto;
}

.medium-container {
	max-width: 850px;
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
	overflow: hidden;
}

ul,
li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

img {
	display: inline-block;
	max-width: 100%;
}

.theme-btn {
	display: inline-block;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.centered {
	text-align: center;
}

.theme-btn .flaticon-arrow {
	display: inherit;
	font-size: inherit;
	margin-left: 3px;
	font-size: 14px;
	-webkit-transform: scaleX(-1) translateY(-1px);
	-moz-transform: scaleX(-1) translateY(-1px);
	-ms-transform: scaleX(-1) translateY(-1px);
	-o-transform: scaleX(-1) translateY(-1px);
	transform: scaleX(-1) translateY(-1px);
}

::-webkit-input-placeholder {
	color: inherit;
}

::-moz-input-placeholder {
	color: inherit;
}

::-ms-input-placeholder {
	color: inherit;
}

::selection {
	background: #8c7c20;
	color: #ffffff;
}

::-moz-selection {
	background: #8c7c20;
	color: #ffffff;
}

::-webkit-selection {
	background: #8c7c20;
	color: #ffffff;
}

/* ----- Input Type Number Remove Arrows/Spinner ----- */

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.no-arrows[type=number] {
	-moz-appearance: textfield;
	appearance: textfield;
}

/* ----- Input Type Search Remove close icon ----- */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

/* ======= ScrollUp ======= */

.customScrollUp {
	text-align: center;
	cursor: pointer;
	position: fixed;
	z-index: 99;
	border: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	bottom: 15px;
	font-size: 16px;
	right: 15px;
	text-decoration: none;
	animation: pulse 2s infinite;
	background: #8c7c20;
	width: 40px;
	height: 40px;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
}

.customScrollUp i {
	font-size: 18px;
	color: #fff;
	position: relative;
	top: 2px;
}

.customScrollUp:hover {
	background: #4c35a9;
}

.ps-30 {
	padding-left: 30px;
}

/*** 

====================================================================
	Buttons Style
====================================================================

 ***/

.theme-btn {
	position: relative;
	transition: all 300ms ease;
}

/* Button style one */
.btn-style-one {
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	text-align: center;
	background-color: #8c7c20;
	overflow: hidden;
	border-radius: 10px;
}

.btn-style-one .btn-title {
	position: relative;
}

.btn-style-one:hover {
	color: #8c7c20;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}

.btn-style-one:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
}

.btn-style-one:hover:before {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/* Button style two */

.btn-style-two {
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	text-align: center;
	background-color: #8c7c20;
	overflow: hidden;
	border-radius: 10px;
}

.btn-style-two .btn-title {
	position: relative;
}

.btn-style-two:hover {
	color: #8c7c20;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}

.btn-style-two:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
}

.btn-style-two:hover:before {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/* Button style three */

.btn-style-three {
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	text-align: center;
	background-color: #1c94d2;
	overflow: hidden;
	border-radius: 10px;
}

.btn-style-three .btn-title {
	position: relative;
}

.btn-style-three:hover {
	color: #1c94d2;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}

.btn-style-three:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
}

.btn-style-three:hover:before {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/* Button style four */

.btn-style-four {
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	text-align: center;
	background-color: #24293d;
	overflow: hidden;
	border-radius: 10px;
}

.btn-style-four .btn-title {
	position: relative;
}

.btn-style-four:hover {
	color: #24293d;
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	-o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.10);
}

.btn-style-four:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
}

.btn-style-four:hover:before {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/* List Style One */

.list-style-one {
	position: relative;
}

.list-style-one li {
	position: relative;
	font-size: 16px;
	line-height: 26px;
	color: #444444;
	font-weight: 400;
	padding-left: 35px;
	margin-bottom: 10px;
}

.list-style-one li:before {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 16px;
	content: "\52";
	font-family: 'ElegantIcons';
	color: #8c7c20;
}

/* List Style Two */

.list-style-two {
	position: relative;
}

.list-style-two li {
	position: relative;
	font-size: 16px;
	line-height: 30px;
	color: #888888;
	font-weight: 400;
	padding-left: 25px;
	margin-bottom: 10px;
}

.list-style-two li:before {
	position: absolute;
	left: 0;
	top: 12px;
	height: 6px;
	width: 6px;
	background-color: #8c7c20;
	content: "";
}

/*** 

====================================================================
	Social Icons
====================================================================

 ***/

/*Social Icon One */

.social-icon-one {
	position: relative;
}

.social-icon-one li {
	position: relative;
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 10px;
}

.social-icon-one li:last-child {
	margin-right: 0;
}

.social-icon-one li a .fab {
	position: relative;
	display: block;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 16px;
	color: #a9b1bf;
	background-color: #373851;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.social-icon-one li a:hover .fab {
	transform: rotate(360deg);
}

.social-icon-one li a:hover .fab {
	background-color: #8c7c20;
	border-color: #8c7c20;
	color: #ffffff;
}

/* Social Icon Two */

.social-icon-two {
	position: relative;
	display: block;
}

.social-icon-two li {
	position: relative;
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 10px;
}

.social-icon-two li a .fab {
	position: relative;
	display: block;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 16px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon-two li a:hover .fab {
	color: #ffffff;
	background-color: #d24c59;
}

/* Social Icon Three */

.social-icon-three {
	position: relative;
	display: block;
}

.social-icon-three li {
	position: relative;
	display: inline-block;
	margin-left: 30px;
	margin-bottom: 10px;
}

.social-icon-three li a {
	position: relative;
	display: block;
	line-height: 30px;
	text-align: center;
	font-size: 16px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon-three li a:hover {
	color: #222222;
}

.theme_color {
	color: #d24c59;
}

.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	/* background-image: url(../images/icons/preloader.svg); */
	background-size: 100px;
}

.preloader:after {
	position: absolute;
	left: 50%;
	top: 50%;
	content: "Wrapped In Grace Conference";
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-top: 45px;
	font-size: 18px;
	line-height: 1em;
	color: #8c7c20;
	text-decoration: underline;
	font-family: "Ubuntu", sans-serif;
	font-weight: 400;
}


img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 40px;
	height: 40px;
	color: #ffffff;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 40px;
	text-align: center;
	z-index: 100;
	cursor: pointer;
	background: #8c7c20;
	display: none;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.scroll-to-top:hover {
	color: #ffffff;
	background: #4c35a9;
}

.tabs-box {
	position: relative;
}

.tabs-box .tab.active-tab {
	display: block;
}

/*** 

====================================================================
	Page Title
====================================================================

***/

.page-title {
	position: relative;
	text-align: center;
	overflow: hidden;
	z-index: 1;
	padding: 120px 0;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
}

.page-title h1 {
	position: relative;
	display: block;
	font-size: 60px;
	color: #ffffff;
	line-height: 1em;
	font-weight: 700;
	margin-bottom: 20px;
	text-transform: capitalize;
}

.page-title .bread-crumb {
	position: relative;
}

.page-title .bread-crumb li {
	position: relative;
	display: inline-block;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 500;
	cursor: default;
	padding-right: 15px;
	margin-right: 15px;
}

.page-title .bread-crumb li:before {
	position: absolute;
	right: -3px;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	content: "|";
}

.page-title .bread-crumb li:last-child {
	padding-right: 0;
	margin-right: 0;
}

.page-title .bread-crumb li:last-child:before {
	display: none;
}

.page-title .bread-crumb li a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.page-title .bread-crumb li a:hover {
	color: #f0c52e;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
	position: relative;
	margin-bottom: 70px;
}

.sec-title .title {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 1em;
	color: #ff8a01;
	font-weight: 500;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	text-transform: uppercase;
	letter-spacing: 5px;
	margin-bottom: 15px;
}

.sec-title h2 {
	position: relative;
	display: inline-block;
	font-size: 48px;
	line-height: 1.2em;
	color: #1e1f36;
	font-weight: 700;
}

.sec-title .text {
	position: relative;
	font-size: 16px;
	line-height: 28px;
	color: #1e1f36;
	margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
	color: #1e1f36;
	-webkit-text-fill-color: inherit;
}

.anim-icons {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
}

.anim-icons.full-width {
	max-width: 100%;
}


.anim-icons .icon {
	position: absolute;
	background-position: center;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Main Header
====================================================================

***/

.main-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .main-box {
	position: relative;
	padding: 25px 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .logo-box {
	position: relative;
	float: left;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .logo-box .logo img {
	display: inline-block;
	max-width: 100%;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .nav-outer {
	position: relative;
	float: right;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.main-header .nav-outer .main-menu {
	position: static;
	float: left;
}

.main-menu .navigation {
	position: static;
	margin: 0px;
}

.main-menu .navigation>li {
	position: relative;
	float: left;
	margin-left: 45px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-menu .navigation>li>a {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
	padding: 10px 0;
	color: #fefefe;
	opacity: 1;
	text-align: center;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-menu ul>li>a.active,
.main-menu ul>li.active {
	color: #8c7c20 !important;
}

.main-menu .navigation>li>a:before {
	position: absolute;
	left: 50%;
	bottom: 10px;
	height: 2px;
	width: 0%;
	content: "";
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-menu .navigation>li.current>a:before,
.main-menu .navigation>li:hover>a:before {
	left: 0;
	width: 100%;
}

.main-menu .navigation>li>ul>li.dropdown>a:before {
	font-family: 'Font Awesome 5 Free';
	content: "\f105";
	position: absolute;
	right: 25px;
	top: 8px;
	width: 10px;
	height: 20px;
	display: block;
	line-height: 20px;
	font-size: 16px;
	font-weight: 900;
	text-align: center;
	z-index: 5;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown:hover>a:after {
	color: #ffffff;
}

.main-menu .navigation>li>ul {
	position: absolute;
	left: 0px;
	top: 100%;
	width: 220px;
	z-index: 100;
	display: none;
	opacity: 0;
	visibility: hidden;
	padding: 20px 0px;
	background: #ffffff;
	border-radius: 3px;
	-webkit-transform-origin: top;
	-moz-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	-moz-transform: rotateX(90deg);
	-webkit-transform: rotateX(90deg);
	-ms-transform: rotateX(90deg);
	-o-transform: rotateX(90deg);
	transform: rotateX(90deg);
	border-bottom: 3px solid #8c7c20;
	border-radius: 0 0 6px 6px;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul:before {
	position: absolute;
	content: '';
	left: 0px;
	top: -30px;
	width: 100%;
	height: 30px;
	display: block;
}

.main-menu .navigation>li>ul.from-right {
	left: auto;
	right: 0px;
}

.main-menu .navigation>li>ul>li {
	position: relative;
	width: 100%;
	margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li:last-child {
	margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>a {
	position: relative;
	display: block;
	padding: 5px 25px;
	line-height: 24px;
	font-weight: 400;
	font-size: 16px;
	text-transform: capitalize;
	color: #222222;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li:hover>a {
	color: #8c7c20;
}

.main-menu .navigation>li>ul>li>ul {
	position: absolute;
	left: 100%;
	top: 100%;
	width: 220px;
	z-index: 100;
	display: none;
	opacity: 0;
	visibility: hidden;
	margin-top: -50px;
	padding: 20px 0px;
	background: #ffffff;
	-webkit-transform-origin: top;
	-moz-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	-webkit-transform: rotateX(90deg);
	-moz-transform: rotateX(90deg);
	-ms-transform: rotateX(90deg);
	-o-transform: rotateX(90deg);
	transform: rotateX(90deg);
	border-bottom: 3px solid #8c7c20;
	border-radius: 0 0 6px 6px;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul>li>ul.from-right {
	left: auto;
	right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
	position: relative;
	width: 100%;
	margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
	margin-bottom: 0px;
}

.main-menu .navigation>li>ul>li>ul>li>a {
	position: relative;
	display: block;
	padding: 5px 25px;
	line-height: 24px;
	font-weight: 400;
	font-size: 16px;
	text-transform: capitalize;
	color: #222222;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a {
	color: #8c7c20;
}

.main-menu .navigation>li.dropdown:hover>ul {
	visibility: visible;
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
	visibility: visible;
	opacity: 1;
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.main-menu .navbar-collapse>ul li.dropdown .dropdown-btn {
	position: absolute;
	right: 10px;
	top: 6px;
	width: 30px;
	height: 30px;
	text-align: center;
	color: #ffffff;
	line-height: 28px;
	border: 1px solid #ffffff;
	background-size: 20px;
	cursor: pointer;
	z-index: 5;
	display: none;
}

/* Outer box */
.main-header .outer-box {
	position: relative;
	float: right;
	margin-left: 50px;
}

.main-header .search-box-outer {
	position: relative;
	float: left;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .search-box-btn {
	position: relative;
	float: left;
	font-size: 24px;
	line-height: 30px;
	cursor: pointer;
	background: none;
	color: #ffffff;
	font-weight: 400;
	cursor: pointer;
	margin-top: 10px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.dropdown-toggle::after {
	display: none;
}

.main-header .search-box-outer .dropdown-menu {
	top: 44px !important;
	left: auto !important;
	right: 0;
	padding: 0px;
	width: 280px;
	border-radius: 0px;
	-webkit-transform: none !important;
	-ms-transform: none !important;
	transform: none !important;
	border-top: 3px solid #df6512;
}

.main-header .search-panel .form-container {
	padding: 25px 20px;
}

.main-header .search-panel .form-group {
	position: relative;
	margin: 0px;
}

.main-header .search-panel input[type="text"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="password"],
.main-header .search-panel select {
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 7px 40px 7px 15px;
	height: 40px;
	font-size: 14px;
	border: 1px solid #e0e0e0;
	background: #ffffff;
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus {
	border-color: #25262c;
}

.main-header .search-panel .search-btn {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 40px;
	height: 40px;
	text-align: center;
	color: #555555;
	font-size: 12px;
	background: none;
	cursor: pointer;
}

.main-header .outer-box .btn-box {
	position: relative;
	float: right;
	padding: 5px 0;
	margin-left: 20px;
}

.main-header .outer-box .btn-box a {
	position: relative;
	float: right;
	padding: 10px 20px;
	line-height: 20px;
	font-size: 14px;
}

.nav-outer .mobile-nav-toggler {
	/* position: relative; */
	/* float: right; */
	font-size: 40px;
	line-height: 50px;
	cursor: pointer;
	color: #8c7c20;
	margin: 7px 0px 2px;
	display: none;
	display:'flex';
	justify-Content: 'space-between'
}

/*** 

====================================================================
	Header Style Two
====================================================================

***/

.header-span {
	height: 90px;
	display: block;
}


.header-style-two .main-box {
	padding: 0;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.header-style-two .outer-box,
.header-style-two .logo-box,
.header-style-two .main-menu .navigation>li {
	padding: 20px 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.header-style-two .search-box-btn,
.header-style-two .main-menu .navigation>li>a {
	color: #3f4161;
}

.header-style-two.fixed-header {
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.main-header.header-style-two.fixed-header .main-box {
	background-color: #ffffff;
	padding: 0;
}

.header-style-two.fixed-header .outer-box,
.header-style-two.fixed-header .logo-box,
.header-style-two.fixed-header .main-menu .navigation>li {
	padding: 10px 0;
}

/*** 

====================================================================
	Header Style Three
====================================================================

***/

.header-style-three .main-menu .navigation>li {
	margin-left: 35px;
}

.header-style-three .nav-outer {
	float: left;
}

.header-style-three .nav-outer .main-menu {
	margin-left: 45px;
}

.header-style-three .outer-box {
	position: relative;
	float: right;
}

.header-style-three .outer-box .btn-box a {
	border-radius: 3px;
}

.c{
	position: relative;
	background-image: url(../images/resource/image002.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 400px;
  	/* text-shadow: 2px 2px black; */
	/* filter: grayscale(100%); */
}

/*** 

====================================================================
		Fixed Header
====================================================================

***/

.main-header.fixed-header .main-box {
	padding: 10px 0;
	background-color: #101130;
}

/*** 

====================================================================
			Mobile Menu
====================================================================

***/

.mobile-menu {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	padding-right: 30px;
	max-width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo {
	position: relative;
	padding: 20px 20px;
	text-align: left;
}

.mobile-menu .nav-logo img {
	max-width: 200px;
}

.mobile-menu-visible {
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-backdrop {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 55%;
	max-height: 100%;
	overflow-y: auto;
	background: #1e1f36;
	padding:50px 0px;
	z-index: 5;
	opacity: 0;
	border-radius: 0px;
	visibility: hidden;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: top right;
	-moz-transform-origin: top right;
	-ms-transform-origin: top right;
	-o-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.mobile-menu-visible .mobile-menu .menu-box {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.mobile-menu .close-btn {
	position: absolute;
	right: 15px;
	top: 25px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	background-color: #8c7c20;
	cursor: pointer;
	z-index: 10;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn {
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	transition: all 0.5s ease;
}

.mobile-menu .close-btn:hover {
	opacity: 0.50;
}

.mobile-menu .navigation {
	position: relative;
	display: block;
	border-top: 1px solid rgba(0, 0, 0, 0.10);
	
}

.mobile-menu .navigation li {
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
	height: 60px;
	padding-left: 10px;
}

.mobile-menu .navigation li>ul>li:last-child {
	border-bottom: none;
}

.mobile-menu .navigation li>ul>li:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>ul>li ul.multi-menu li:last-child {
	border-bottom: none;
}

.mobile-menu .navigation li>a {
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: 16px;
	color: #404040;
	font-weight: 600;
	text-transform: capitalize;
	
}

.mobile-menu .navigation li:hover>a,
.mobile-menu .navigation li.current>a {
	color: #8c7c20;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 44px;
	height: 44px;
	text-align: center;
	font-size: 16px;
	line-height: 44px;
	color: #404040;
	cursor: pointer;
	z-index: -1;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after {
	content: '';
	position: absolute;
	left: 0px;
	top: 10px;
	width: 1px;
	height: 24px;
	border-left: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li ul.sub-menu {
	overflow: hidden;
	max-height: 0;
	transition: max-height .8s ease;
}

.mobile-menu .navigation li.open ul.sub-menu {
	max-height: 2000px;
	transition: max-height 1.2s ease-in-out;
}

.mobile-menu .navigation li ul.sub-menu li {
	padding-left: 25px;
}

.mobile-menu .navigation li ul.sub-menu li ul.multi-menu {
	overflow: hidden;
	max-height: 0;
	transition: max-height .8s ease;
}

.mobile-menu .navigation li ul.sub-menu li.open ul.multi-menu {
	max-height: 2000px;
	transition: max-height 1.2s ease-in-out;
}

.no-color-palate .color-palate {
	display: none;
}

/*** 

====================================================================
		Banner Section
====================================================================

***/

.banner-section {
	position: relative;
}

.banner-carousel {
	position: relative;
}

.banner-carousel .slide-item {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 250px 0 200px;
	text-align: center;
}

.banner-carousel .slide-item:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	/* background-color: #000000; */
	/* opacity: .70; */
	content: "";
}

.banner-carousel .content-box {
	position: relative;
	z-index: 2;
}

.banner-carousel .content-box .title {
	display: block;
	font-size: 28px;
	color: #8c7c20;
	font-weight: 500;
	margin-bottom: 25px;
	opacity: 0;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.banner-carousel .slick-current .content-box .title {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

.banner-carousel .content-box h2 {
	display: block;
	font-size: 74px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 30px;
	opacity: 0;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.banner-carousel .slick-current .content-box h2 {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-carousel .content-box .info-list {
	position: relative;
	margin-bottom: 45px;
	opacity: 0;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.banner-carousel .slick-current .content-box .info-list {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.banner-carousel .content-box .info-list li {
	position: relative;
	display: inline-block;
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
	font-weight: 400;
	margin: 0 10px;
	text-transform: uppercase;
}

.banner-carousel .content-box .info-list li .icon {
	color: #8c7c20;
	margin-right: 10px;
	float: left;
	font-size: 18px;
	line-height: 26px;
}

.banner-carousel .content-box .btn-box {
	opacity: 0;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.banner-carousel .slick-current .content-box .btn-box {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.slick-next::before,
.slick-prev::before {
	content: "";
}

.banner-carousel .slick-next,
.banner-carousel .slick-prev {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 100px;
	width: 30px;
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.10);
	font-size: 20px;
	line-height: 100px;
	text-align: center;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	z-index: 1;
}

.banner-carousel .slick-next {
	left: auto;
	right: 0;
}

.banner-carousel .slick-next:hover,
.banner-carousel .slick-prev:hover {
	background-color: rgba(255, 255, 255, .90);
	color: #222222;
}

/*** 

====================================================================
		Banner Conference
====================================================================

***/

.banner-conference {
	position: relative;
	padding: 200px 0;
	overflow: hidden;
}

.banner-conference .icons {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.banner-conference .icons .icon-one {
	position: absolute;
	left: 10% !important;
	top: 17% !important;
	width: 12px;
	height: 12px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-two {
	position: absolute;
	left: 35% !important;
	top: 25% !important;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-three {
	position: absolute;
	left: 6% !important;
	top: 58% !important;
	width: 34px;
	height: 10px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-four {
	position: absolute;
	left: 56% !important;
	top: 60% !important;
	width: 34px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-five {
	position: absolute;
	left: 79% !important;
	top: 25% !important;
	width: 35px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-six {
	position: absolute;
	left: 90% !important;
	top: 88% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-seven {
	position: absolute;
	left: 80% !important;
	top: 60% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-eight {
	position: absolute;
	left: 52% !important;
	top: 40% !important;
	width: 12px;
	height: 12px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-nine {
	position: absolute;
	left: 52% !important;
	top: 12% !important;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-ten {
	position: absolute;
	left: 34% !important;
	top: 68% !important;
	width: 34px;
	height: 10px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-eleven {
	position: absolute;
	left: 89% !important;
	top: 80% !important;
	width: 34px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-twelve {
	position: absolute;
	left: 69% !important;
	top: 70% !important;
	width: 35px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-thirteen {
	position: absolute;
	left: 200% !important;
	top: 88% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference .icons .icon-fourteen {
	position: absolute;
	left: 95% !important;
	top: 95% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icon-dots {
	left: 40%;
	bottom: 180px;
}

.banner-conference-two .layer-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference-two .images-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference-two .images-outer .speaker-img {
	position: absolute;
	right: 150px;
	top: 52%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.banner-conference-two .content-box {
	position: relative;
	z-index: 99;
}

.banner-conference-two .content-box .title {
	display: block;
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 20px;
}

.banner-conference-two .content-box h2 {
	display: block;
	font-size: 50px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 50px;
}

.banner-conference-two .time-counter {
	position: relative;
	margin-bottom: 30px;
}

.banner-conference-two .time-countdown {
	position: relative;
}

.banner-conference-two .time-countdown .counter-column {
	position: relative;
	display: inline-block;
	margin-right: 30px;
	width: 90px;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	font-weight: 500;
}

.banner-conference-two .time-countdown .counter-column:last-child {
	margin-right: 0;
}

.banner-conference-two .time-countdown .counter-column:before {
	position: absolute;
	content: ':';
	top: 10px;
	right: -20px;
	font-size: 30px;
	line-height: 1em;
	color: #ffffff;
}

.banner-conference-two .time-countdown .counter-column:after {
	position: absolute;
	left: 0;
	top: -20px;
	height: 100px;
	width: 90px;
	z-index: -1;
	border-radius: 5px 30px 5px 30px;
	background-color: #8c7c20;
	content: "";
}

.banner-conference-two .time-countdown .counter-column:last-child:before {
	display: none;
}

.banner-conference-two .time-countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1em;
	color: #ffffff;
	font-weight: 700;
}

.banner-conference-two .content-box .btn-box {
	position: relative;
	margin-top: 60px;
}

.banner-conference .icon-circle-3 {
	left: -150px;
	top: -150px;
}

.banner-conference .icon-dots {
	left: 40%;
	bottom: 180px;
}

.banner-conference .layer-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference .gradient-layer {
	position: absolute;
	left: 120px;
	width: 140%;
	height: 140%;
	top: -20%;
	border-radius: 50%;
	-webkit-transform: skew(-200deg);
	-moz-transform: skew(-200deg);
	-ms-transform: skew(-200deg);
	-o-transform: skew(-200deg);
	transform: skew(-200deg);
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	opacity: 2;
}

.banner-conference .images-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference .images-outer .speaker-img {
	position: absolute;
	left: -100px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.banner-conference .content-box {
	position: relative;
	z-index: 99;
}

.banner-conference .content-box .title {
	display: block;
	font-size: 24px;
	color: #222222;
	font-weight: 500;
	margin-bottom: 20px;
}

.banner-conference .content-box h2 {
	display: block;
	font-size: 54px;
	line-height: 1.2em;
	color: #e78f45;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 700;
	margin-bottom: 50px;
}

.banner-conference .time-counter {
	position: relative;
	margin-bottom: 30px;
}

.banner-conference .time-countdown {
	position: relative;
}

.banner-conference .time-countdown .counter-column {
	position: relative;
	display: inline-block;
	margin-right: 30px;
	width: 80px;
	text-align: center;
	font-size: 14px;
	color: #4a763c;
	font-weight: 500;
}

.banner-conference .time-countdown .counter-column:last-child {
	margin-right: 0;
}


.banner-conference .time-countdown .counter-column:before {
	position: absolute;
	content: ':';
	top: 10px;
	right: -20px;
	font-size: 30px;
	line-height: 1em;
	color: #d84343;
}

.banner-conference .time-countdown .counter-column:after {
	position: absolute;
	left: 0;
	top: -20px;
	height: 80px;
	width: 80px;
	border-radius: 50px;
	border-top: 5px solid #d84343;
	content: "";
}


.banner-conference .time-countdown .counter-column:last-child:before {
	display: none;
}

.banner-conference .time-countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1em;
	color: #d84343;
	font-weight: 700;
}

.banner-conference .content-box .btn-box {
	position: relative;
}

/*** 

====================================================================
		Banner MeetUp
====================================================================

***/

.banner-meetup {
	position: relative;
	padding: 140px 0;
	z-index: 1;
}

.banner-meetup .bg-pattern {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.banner-meetup .content-box {
	position: relative;
	text-align: center;
}

.banner-meetup h2 {
	display: block;
	font-size: 80px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 40px;
	text-shadow: 0 20px 40px rgba(0, 0, 0, .80);
}

.banner-meetup .address {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 26px;
	color: #ffffff;
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
	text-shadow: 0 10px 20px rgba(0, 0, 0, 0.80);
}

.banner-meetup .address .icon {
	position: relative;
	display: inline-block;
	margin-right: 10px;
	font-size: 18px;
	line-height: 26px;
	color: #8c7c20;
}

.banner-meetup .btn-box {
	position: relative;
	text-align: center;
	margin-top: 30px;
}

.banner-meetup .btn-box .theme-btn {
	padding: 15px 34px;
	line-height: 20px;
	border-radius: 5px;
	font-weight: 700;
}

.banner-meetup .countdown {
	position: relative;
	text-align: center;
	font-family: 'Orbitron', sans-serif;
}

.banner-meetup .countdown .time-counter {
	position: relative;
	display: inline-block;
}

.banner-meetup .countdown .counter-column:before {
	position: absolute;
	content: '';
	left: -10px;
	top: -15px;
	width: 120px;
	height: 120px;
	opacity: 0.5;
	border: 2px solid #ffffff;
	transform: rotate(45deg);
}

.banner-meetup .countdown .counter-column {
	position: relative;
	display: inline-block;
	line-height: 30px;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: transparent;
	border-radius: 50%;
	color: #fafafa;
	width: 100px;
	margin: 0 20px 30px;
	text-shadow: 0 10px 20px rgba(0, 0, 0, 0.60);

}

.banner-meetup .countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 45px;
	line-height: 1.2em;
	color: #fafafa;
	width: auto;
	font-weight: 600;
	letter-spacing: 0;
	text-shadow: 0 15px 20px rgba(0, 0, 0, 0.60);
}

.banner-meetup .btn-box .theme-btn {
	position: relative;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.30);
}

/*** 

====================================================================
		About Section
====================================================================

***/

.about-section {
	position: relative;
	padding: 120px 0 70px;
}

.about-section .content-column {
	position: relative;
	margin-bottom: 50px;
}

.about-section .content-column .inner-column {
	position: relative;
	padding-right: 80px;
}

.about-section .content-column .sec-title {
	position: relative;
	margin-bottom: 20px;
}

.about-section .content-column .sec-title .text {
	margin-top: 20px;
}

.about-section .content-column .sec-title h2 {
	font-size: 40px;
}

.about-section .content-column .list-style-one {
	margin-bottom: 30px;
}

.about-section .content-column .btn-box {
	position: relative;
}

.about-section .content-column .btn-box a {
	padding: 15px 30px;
	line-height: 20px;
	font-weight: 600;
	font-size: 15px;
}

.about-section .image-column {
	position: relative;
	margin-bottom: 50px;
}

.about-section .image-column .image-box {
	position: relative;
	padding-left: 80px;
}

.about-section .image-column .image-box img {
	position: relative;
	z-index: 1;
	/* border-radius: 50%; */
	border: 15px solid #ffffff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

.about-section .image-column .image {
	position: relative;
	margin-bottom: 0;
}

.about-section .image-column .image:before {
	position: absolute;
	right: -10%;
	top: -10%;
	height: 120%;
	width: 120%;
	background-image: url(../images/icons/shape-2.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	-webkit-animation: dizzling 10s infinite;
	-moz-animation: dizzling 10s infinite;
	-ms-animation: dizzling 10s infinite;
	-o-animation: dizzling 10s infinite;
	animation: dizzling 10s infinite;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	content: "";
}

@keyframes dizzling {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	50% {
		-webkit-transform: rotate(5deg);
		-moz-transform: rotate(5deg);
		-ms-transform: rotate(5deg);
		-o-transform: rotate(5deg);
		transform: rotate(5deg);
	}

	100% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
}

.icon-shape-1 {
	width: 484px;
	height: 225px;
	background-image: url(../images/icons/shape-1.png);
}

.icon-shape-2 {
	width: 325px;
	height: 280px;
	background-image: url(../images/icons/shape-2.png);
}

.icon-line-1 {
	width: 128px;
	height: 128px;
	background-image: url(../images/icons/icon-line-1.png);
}

.icon-dots {
	width: 72px;
	height: 60px;
	background-image: url(../images/icons/icon-dots.png);
}

.icon-dots-3 {
	width: 204px;
	height: 114px;
	background-image: url(../images/icons/icon-dots-3.png);
}

.icon-circle-1 {
	width: 500px;
	height: 500px;
	background-image: url(../images/icons/icon-circle-1.png);
	background-size: 100%;
}

.icon-circle-blue {
	width: 300px;
	height: 300px;
	background-image: url(../images/icons/circle-blue.png);
}

.about-section .icon-circle-blue {
	right: -150px;
	bottom: -150px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

.about-section .icon-dots {
	left: 50%;
	top: 30%;
}

.about-section .icon-circle-1 {
	left: -200px;
	top: -200px;
	opacity: .05;
}

/*** 

====================================================================
		About Section Two
====================================================================

***/

.about-section-two {
	position: relative;
	padding: 120px 0 70px;
}

.about-section-two .content-column {
	position: relative;
	margin-bottom: 50px;
}

.about-section-two .content-column .inner-column {
	position: relative;
	padding-top: 50px;
}

.about-section-two .content-column .sec-title {
	position: relative;
	margin-bottom: 20px;
}

.about-section-two .content-column .sec-title .text {
	margin-top: 20px;
}

.about-section-two .content-column .sec-title h2 {
	font-size: 40px;
}

.about-section-two .content-column .list-style-one {
	margin-bottom: 30px;
}

.about-section-two .content-column .btn-box {
	position: relative;
}

.about-section-two .content-column .btn-box a {
	padding: 15px 30px;
	line-height: 20px;
	font-weight: 600;
	font-size: 15px;
	border: 1px solid transparent;
}

.about-section-two .about-block {
	position: relative;
	margin-bottom: 30px;
}

.about-section-two .about-block .inner-box {
	position: relative;
}

.about-section-two .about-block h4 {
	position: relative;
	padding-left: 35px;
	font-size: 24px;
	line-height: 50px;
	color: #24293d;
}

.about-section-two .about-block .icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 24px;
	line-height: 50px;
	color: #e6275a;
}

.about-section-two .about-block .text {
	font-size: 16px;
	color: #888888;
	line-height: 1.2em;
	font-weight: 500;
}

.about-section-two .image-column {
	position: relative;
	margin-bottom: 50px;
}

.about-section-two .image-column .image-box {
	position: relative;
	padding-right: 50px;
}

.about-section-two .image-column .image-box img {
	display: block;
	width: 100%;
	z-index: 1;
}

.about-section-two .image-column .image {
	position: relative;
	margin-bottom: 0;
}

.about-section-two .image-column .image:before {
	position: absolute;
	left: -1px;
	top: -1px;
	right: -1px;
	bottom: -1px;
	background-image: url(../images/icons/icon-shape.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	content: "";
	z-index: 8;
}


.about-section-two .icon-circle-blue {
	left: -150px;
	bottom: -150px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

.about-section-two .icon-dots {
	left: 5%;
	top: 20%;
}

.about-section-two .icon-circle-1 {
	right: -200px;
	top: -200px;
	opacity: .05;
}

/*** 

====================================================================
		Coming Soon
====================================================================

***/

.coming-soon-section {
	position: relative;
	z-index: 2;
}

.coming-soon-section .outer-box {
	position: relative;
	display: block;
	padding: 30px 80px;
	background-color: #ffffff;
	text-align: center;
	margin-top: -72px;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.30);
}

.coming-soon-section .timer-column {
	position: relative;
}

.coming-soon-section .time-counter {
	position: relative;
}

.coming-soon-section .time-countdown {
	position: relative;
}

.coming-soon-section .time-countdown .counter-column {
	position: relative;
	display: inline-block;
	margin: 0px 40px;
	width: 100px;
	text-align: center;
	font-size: 18px;
	color: #ffffff;
	font-weight: 500;
}

.coming-soon-section .time-countdown .counter-column:before {
	position: absolute;
	content: ':';
	top: 10px;
	right: -50px;
	font-size: 56px;
	line-height: 1em;
	color: #ffffff;
}

.coming-soon-section .time-countdown .counter-column:last-child:before {
	display: none;
}

.coming-soon-section .time-countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 60px;
	line-height: 1em;
	color: #ffffff;
	font-weight: 700;
}

/*** 

====================================================================
		Features Section
====================================================================

***/

.features-section {
	position: relative;
	padding: 120px 0 80px;
}

.feature-block {
	position: relative;
	margin-bottom: 40px;
}

.feature-block .inner-box {
	position: relative;
	padding: 40px 30px;
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	box-shadow: 0 30px 40px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	text-align: center;
	overflow: hidden;
}

.feature-block .inner-box:hover {
	box-shadow: none;
}

.feature-block .icon-box {
	position: relative;
	display: block;
	margin-bottom: 30px;
}

.feature-block .icon-box .icon {
	position: relative;
	display: inline-block;
	font-size: 70px;
	line-height: 1em;
	color: #40cbb4;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.feature-block:nth-child(3n + 2) h4 a:hover,
.feature-block:nth-child(3n + 2) .icon-box .icon {
	color: #4c35a9;
}

.feature-block:nth-child(3n + 3) h4 a:hover,
.feature-block:nth-child(3n + 3) .icon-box .icon {
	color: #e6275a;
}

.feature-block .inner-box:hover .icon-box .icon {
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.feature-block h4 {
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 1.2em;
	color: #12114a;
	font-weight: 700;
	margin-bottom: 20px;
}

.feature-block h4 a {
	color: #12114a;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.feature-block h4 a:hover {
	color: #40cbb4;
}

.feature-block .text {
	position: relative;
	display: block;
	font-size: 15px;
	line-height: 24px;
	color: #888888;
	font-weight: 400;
	margin-bottom: 25px;
	cursor: default;
}

.feature-block .link-box {
	position: relative;
	display: block;
}

.feature-block .link-box a {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	line-height: 20px;
	font-size: 14px;
	color: #ffffff;
	background-color: #40cbb4;
	border-radius: 10px;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.feature-block .inner-box:hover .link-box a {
	border-radius: 0px;
	color: #40cbb4;
	background: none;
	border-color: #40cbb4;
}

.feature-block:nth-child(3n + 2) .link-box a {
	background-color: #4c35a9;
}

.feature-block:nth-child(3n + 2) .inner-box:hover .link-box a {
	background: none;
	color: #4c35a9;
	border-color: #4c35a9;
}

.feature-block:nth-child(3n + 3) .link-box a {
	background-color: #e6275a;
}

.feature-block:nth-child(3n + 3) .inner-box:hover .link-box a {
	background: none;
	color: #e6275a;
	border-color: #e6275a;
}

.features-section .anim-icons {
	max-width: 100%;
}

.icon-shape-3 {
	height: 490px;
	width: 290px;
	background-image: url(../images/icons/shape-3.png);
	background-size: 100%;
}

.features-section .icon-shape-3 {
	top: 0px;
	left: -110px;
	opacity: .70;
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
}

.features-section .icon-line-1 {
	right: 10%;
	top: 35%;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

.features-section .icon-dots {
	right: 10%;
	top: 35%;
}

/*** 

====================================================================
	Features Section Two
====================================================================

***/

.features-section-two {
	position: relative;
	padding: 0px 0 100px;
}

.features-section-two .title-block {
	position: relative;
	margin-bottom: 40px;
}

.features-section-two .title-block .inner-box {
	padding: 50px 30px 0;
	background-color: #ffffff;
}

.features-section-two .title-block .sec-title {
	margin-bottom: 0;
}

.features-section-two .title-block .sec-title .title {
	margin-bottom: 5px;
}

.feature-block-two {
	position: relative;
	margin-bottom: 40px;
}

.feature-block-two .inner-box {
	position: relative;
	padding: 40px 30px;
	padding-left: 100px;
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	box-shadow: 0 30px 40px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	overflow: hidden;
}

.feature-block-two .inner-box:hover {
	box-shadow: none;
}

.feature-block-two .icon-box {
	position: absolute;
	left: 30px;
	top: 50px;
	display: block;
	margin-bottom: 30px;
}

.feature-block-two .icon-box .icon {
	position: relative;
	display: inline-block;
	font-size: 50px;
	line-height: 1em;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	background: none;
	color: #8c7c20;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.feature-block-two .inner-box:hover .icon-box .icon {
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.feature-block-two h4 {
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 1.2em;
	color: #12114a;
	font-weight: 700;
	margin-bottom: 10px;
}

.feature-block-two h4 a {
	color: #12114a;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.feature-block-two h4 a:hover {
	color: #8c7c20;
}

.feature-block-two .text {
	position: relative;
	display: block;
	font-size: 15px;
	line-height: 24px;
	color: #888888;
	font-weight: 400;
	cursor: default;
}

.feature-block-two .link-box {
	position: relative;
	display: block;
}

.feature-block-two .link-box a {
	padding: 10px 20px;
	line-height: 20px;
	font-size: 14px;
}

.features-section-two .twist-line-1 {
	left: -110px;
	top: 290px;
}

.features-section-two .twist-line-2 {
	left: -10%;
	top: 80px;
}

.features-section-two .twist-line-3 {
	left: 90px;
	top: 50px;
}

.twist-line-1 {
	height: 70px;
	width: 80px;
	background-image: url(../images/icons/twist-line-1.png);
}

.twist-line-2 {
	height: 73px;
	width: 70px;
	background-image: url(../images/icons/twist-line-2.png);
}

.twist-line-3 {
	height: 73px;
	width: 70px;
	background-image: url(../images/icons/twist-line-3.png);
}

/*** 

====================================================================
	Features Section Three
====================================================================

***/

.features-section-three {
	position: relative;
	padding: 120px 0 80px;
}

.features-section-three.no-pd-top {
	padding-top: 0;
}

.feature-block-three {
	position: relative;
	margin-bottom: 40px;
}

.feature-block-three .inner-box {
	position: relative;
	padding: 20px 30px;
	padding-left: 170px;
	padding-right: 100px;
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	box-shadow: 0 30px 40px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	overflow: hidden;
}

.feature-block-three .inner-box:hover {
	box-shadow: none;
}

.feature-block-three .icon-box {
	position: absolute;
	left: 50px;
	top: 0;
	height: 100%;
	width: 70px;
	padding: 0 20px;
}

.feature-block-three .icon-box:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #40cbb4;
	content: "";
	-webkit-transform: skew(-15deg);
	-moz-transform: skew(-15deg);
	-ms-transform: skew(-15deg);
	-o-transform: skew(-15deg);
	transform: skew(-15deg);
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.feature-block-three .inner-box:hover .icon-box:before {
	-webkit-transform: skew(15deg);
	-moz-transform: skew(15deg);
	-ms-transform: skew(15deg);
	-o-transform: skew(15deg);
	transform: skew(15deg);
}

.feature-block-three .icon-box .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 40px;
	font-size: 40px;
	line-height: 1em;
	margin-top: -20px;
	margin-left: -20px;
	color: #ffffff;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.feature-block-three .inner-box:hover .icon-box .icon {
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.feature-block-three h4 {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #12114a;
	font-weight: 700;
	margin-bottom: 10px;
}

.feature-block-three h4 a {
	color: #12114a;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.feature-block-three h4 a:hover {
	color: #40cbb4;
}

.feature-block-three .text {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 24px;
	color: #888888;
	font-weight: 400;
	cursor: default;
}

.feature-block-three .link-box {
	position: absolute;
	right: 30px;
	top: 50%;
	margin-top: -20px;
	font-size: 20px;
	border-radius: 50%;
}

.feature-block-three .link-box a {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	line-height: 20px;
	font-size: 14px;
	color: #ffffff;
	background-color: #40cbb4;
	border-radius: 10px;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.feature-block-three .inner-box:hover .link-box a {
	transform: rotate(360deg);
	border-radius: 0px;
}

.feature-block-three .inner-box:hover .link-box a {
	color: #ffffff;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.10);
}

.feature-block-three:nth-child(3n + 2) .icon-box:before,
.feature-block-three:nth-child(3n + 2) .link-box a {
	background-color: #4c35a9;
}

.feature-block-three:nth-child(3n + 3) .icon-box:before,
.feature-block-three:nth-child(3n + 3) .link-box a {
	background-color: #e6275a;
}

.feature-block-three:nth-child(3n + 4) .icon-box:before,
.feature-block-three:nth-child(3n + 4) .link-box a {
	background-color: #1c94d2;
}

/***

====================================================================
			Fluid Section One
====================================================================

***/

.fluid-section-one {
	position: relative;
	background-color: #1e1f36;
}

.fluid-section-one .outer-box {
	position: relative;
}

.fluid-section-one .content-column {
	position: relative;
	float: right;
	width: 50%;
	padding: 0 15px;
}

.fluid-section-one .content-column .inner-column {
	position: relative;
	max-width: 600px;
	padding: 80px 20px;
	margin: 0 auto;
}

.fluid-section-one .content-column h3 {
	position: relative;
	font-size: 40px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 28px;
}

.fluid-section-one .content-column .text {
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	margin-bottom: 30px;
}

.fluid-section-one .content-column .theme-btn {
	border-radius: 5px;
	box-shadow: 0 10px 30px rgba(80, 4, 42, .15);
}

.fluid-section-one .image-column {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 50%;
	background-repeat: no-repeat;
	background-position: center left;
	background-size: cover;
}

.fluid-section-one .image-column .image-box {
	display: none;
}

.fluid-section-one .image-column .image {
	position: relative;
	margin-bottom: 0;
}

.fluid-section-one .image-column .image img {
	display: block;
	width: 100%;
	height: auto;
}

/*** 

====================================================================
		Speakers Section
====================================================================

***/

.speakers-section {
	position: relative;
	padding: 120px 0 90px;
}

.speakers-section:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(2, 29, 55, 0.557);
	content: "";
}

.speaker-block {
	position: relative;
	margin-bottom: 40px;
}

.speaker-block .inner-box {
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	border: 10px solid #ffffff;
	box-shadow: 0 30px 30px rgba(0, 0, 0, 0.40);
	max-width: 300px;
	margin: 0 auto;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 500ms ease;
}

.speaker-block .inner-box:hover {
	box-shadow: none;
}

.speaker-block .image-box {
	position: relative;
}

.speaker-block .image-box .image {
	position: relative;
	margin-bottom: 0;
}

.speaker-block .image-box .image img {
	display: block;
	width: 100%;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block .inner-box:hover .image img {
	opacity: .80;
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.speaker-block .info-box {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	text-align: center;
	background-color: rgba(28, 139, 210, .70);
	background-color: #1e1f36;


	-webkit-transition: -webkit-transform 0.4s ease;
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.speaker-block .inner-box:hover .info-box {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
	opacity: 1;
}


.speaker-block .info-box .inner {
	position: absolute;
	left: 0;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	padding: 0 40px;
}

.speaker-block .info-box .name {
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	opacity: 0;
	margin-bottom: 5px;
	-webkit-transform: translateY(20px);
	-moz-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-o-transform: translateY(20px);
	transform: translateY(20px);
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 300ms ease;
}

.speaker-block .inner-box:hover .info-box .name {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}


.speaker-block .info-box .name a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block .info-box .designation {
	position: relative;
	display: block;
	font-size: 13px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 15px;
	opacity: 0;
	-webkit-transform: translateY(20px);
	-moz-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-o-transform: translateY(20px);
	transform: translateY(20px);
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 300ms ease;
}

.speaker-block .inner-box:hover .info-box .designation {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
}

.speaker-block .social-links {
	position: relative;
	opacity: 0;
	-webkit-transform: translateY(20px);
	-moz-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-o-transform: translateY(20px);
	transform: translateY(20px);
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 300ms ease;
}

.speaker-block .inner-box:hover .social-links {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 700ms;
	-moz-transition-delay: 700ms;
	-ms-transition-delay: 700ms;
	-o-transition-delay: 700ms;
	transition-delay: 700ms;
}

.speaker-block .social-links li {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
}

.speaker-block .social-links li a i {
	position: relative;
	display: block;
	width: 32px;
	height: 32px;
	font-size: 16px;
	line-height: 30px;
	background-color: #ffffff;
	text-align: center;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block .social-links li a:hover i {
	color: #ffffff;
}

/*** 

====================================================================
	Speakers Section Two
====================================================================

***/

.speakers-section-two {
	position: relative;
	padding: 120px 0 70px;
	overflow: hidden;
}

.speaker-block-two {
	position: relative;
	margin-bottom: 50px;
}

.speaker-block-two .inner-box {
	position: relative;
	background-color: #ffffff;
	overflow: hidden;
	padding: 50px 0 40px;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.10);
	border: 1px solid #dddddd;
	border-bottom: 5px solid #1c94d2;
	max-width: 300px;
	margin: 0 auto;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.speaker-block-two .inner-box:hover {
	box-shadow: none;
}

.speaker-block-two .inner-box:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/speaker-bg.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	content: "";
	opacity: .30;
}

.speaker-block-two .info-box {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	border-radius: 0 30px 30px 0;
	background-color: #1c94d2;
	margin-bottom: 50px;
}

.speaker-block-two .info-box .name {
	position: relative;
	font-size: 18px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 500;
}

.speaker-block-two .info-box .name a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-two .info-box .name a:hover {
	color: #ffffff;
}

.speaker-block-two .info-box .designation {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
}

.speaker-block-two .image-box {
	position: relative;
	display: block;
	margin-bottom: 30px;
	text-align: center;
}

.speaker-block-two .image-box .image {
	position: relative;
	display: inline-block;
	height: 150px;
	width: 150px;
	overflow: hidden;
	border: 4px solid #1c94d2;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
	border-radius: 50%;
	z-index: 9;
	margin-bottom: 0;
}

.speaker-block-two .image-box .image img {
	display: block;
	width: 100%;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-two .inner-box:hover .image img {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.speaker-block-two .social-box {
	position: relative;
	display: block;
	text-align: center;
}

.speaker-block-two .social-links li {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
}

.speaker-block-two .social-links li a i {
	position: relative;
	display: block;
	width: 32px;
	height: 32px;
	border: 1px solid #eeeeee;
	font-size: 16px;
	line-height: 30px;
	background-color: #ffffff;
	text-align: center;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-two .social-links li a:hover i {
	color: #ffffff;
}

.speakers-section-two .anim-icons {
	max-width: 100%;
}

.speakers-section-two .icon-shape-1 {
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
	left: 0;
	top: 0;
}

.speakers-section-two .icon-circle-3 {
	left: -90px;
	bottom: -90px;
	opacity: .70;
}

.speakers-section-two .icon-circle-4 {
	right: -75px;
	top: 30%;
}

.icon-circle-4 {
	height: 156px;
	width: 156px;
	background-image: url(../images/icons/icon-circle-4.png);
}

.icon-circle-3 {
	height: 360px;
	width: 360px;
	background-image: url(../images/icons/icon-circle-3.png);
	background-size: 100%;
}

/*** 

====================================================================
	Speakers Section Three
====================================================================

***/

.speakers-section-three {
	position: relative;
	padding: 120px 0 90px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}

.speaker-block-three {
	position: relative;
	margin-bottom: 50px;
}

.speaker-block-three .inner-box {
	position: relative;
	background-color: #ffffff;
	overflow: hidden;
	padding-bottom: 30px;
	max-width: 300px;
	margin: 0 auto;
	overflow: hidden;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.speaker-block-three .inner-box:hover {
	box-shadow: 0 0px 5px rgba(0, 0, 0, 0.10);
}

.speaker-block-three .image-box {
	position: relative;
	display: block;
	text-align: center;
	padding: 50px 0;
}

.speaker-block-three .image-box:before {
	position: absolute;
	left: -10px;
	right: -10px;
	top: -4px;
	height: 100%;
	background-image: url(../images/icons/shape-5.png);
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
	background-repeat: no-repeat;
	background-position: right top;
	background-size: cover;
	content: "";
}

.speaker-block-three .image-box .image {
	position: relative;
	display: inline-block;
	height: 150px;
	width: 150px;
	overflow: hidden;
	border: 4px solid #682372;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.30);
	border-radius: 50%;
	z-index: 9;
	margin-bottom: 0;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.speaker-block-three .inner-box:hover .image-box .image {
	border-radius: 0px;
}

.speaker-block-three .image-box .image img {
	display: block;
	width: 100%;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-three .inner-box:hover .image img {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.speaker-block-three .info-box {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	padding-right: 10px;
	background-color: #8c7c20;
}

.speaker-block-three .info-box:before {
	position: absolute;
	left: 0;
	right: -30px;
	background-color: #8c7c20;
	content: "";
	top: 0;
	height: 100%;
	-webkit-transform: skew(-30deg);
	-moz-transform: skew(-30deg);
	-ms-transform: skew(-30deg);
	-o-transform: skew(-30deg);
	transform: skew(-30deg);
	border-radius: 0 14px 14px 0;
}

.speaker-block-three .info-box .name {
	position: relative;
	font-size: 18px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 500;
}

.speaker-block-three .info-box .name a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-three .info-box .name a:hover {
	color: #ffffff;
}

.speaker-block-three .info-box .designation {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
}

.speaker-block-three .social-box {
	position: absolute;
	top: 90px;
	transform: translateX(-50px);
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.speaker-block-three .social-box .social-links li {
	margin-bottom: 4px;
}

.speaker-block-three .inner-box:hover .social-box {
	transform: translateX(10px);
}

.speaker-block-two .social-links li {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
}

.speaker-block-three .social-links li a i {
	position: relative;
	display: block;
	width: 32px;
	height: 32px;
	border: 1px solid #eeeeee;
	font-size: 16px;
	line-height: 30px;
	background-color: #ffffff;
	text-align: center;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.speaker-block-three .social-links li a:hover i {
	color: #ffffff;
}

/*** 

====================================================================
	Speaker Detail								
====================================================================

***/

.speaker-detail {
	position: relative;
	padding: 120px 0 70px;
}

.speaker-detail .image-column {
	position: relative;
	margin-bottom: 50px;
}

.speaker-detail .image-column .image-box {
	position: relative;
	padding-right: 20px;
}

.speaker-detail .image-column .image {
	position: relative;
	margin-bottom: 30px;
	overflow: hidden;
	border: 10px solid #ffffff;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.speaker-detail .image-column .image img {
	display: block;
	width: 100%;
}

.speaker-detail .info-column {
	position: relative;
}

.speaker-detail .info-column .inner-column {
	position: relative;
}

.speaker-detail .info-column h1 {
	position: relative;
	font-size: 50px;
	line-height: 1em;
	font-weight: 700;
	margin-bottom: 20px;
}

.speaker-detail .info-column h3 {
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1em;
	font-weight: 600;
	margin-bottom: 20px;
	color: #101130;
}

.speaker-detail .info-column h4 {
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1em;
	font-weight: 500;
	margin-bottom: 20px;
	color: #222222;
}

.speaker-detail .info-column h5 {
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 1em;
	font-weight: 500;
	margin-bottom: 10px;
	color: rgba(68, 16, 102, 1);
}

.speaker-detail .info-column .text-box {
	position: relative;
}

.speaker-detail .info-column p {
	position: relative;
	font-size: 16px;
	line-height: 1.6em;
	color: #888888;
	margin-bottom: 30px;
}

.speaker-detail .speaker-session-info {
	position: relative;
	margin-bottom: 30px;
}

.speaker-detail .speaker-session-info p {
	margin-bottom: 0;
	color: rgb(247, 0, 104);
}


.speaker-detail .bottom-box {
	position: relative;
	padding-left: 80px;
	min-height: 50px;
	margin-bottom: 30px;
}

/*** 

====================================================================
	schedule Section
====================================================================

***/

.schedule-section {
	position: relative;
	padding: 0px 0 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: #fafafa;
	overflow: hidden;
}

.schedule-section .icon-circle-4 {
	right: -200px;
	bottom: 160px;
}

.schedule-section .icon-circle-3 {
	left: -375px;
	top: 340px;
	opacity: .30;
}

.tabs-box {
	position: relative;
}

.tabs-box .tab {
	display: none;
}

.tabs-box .tab.active {
	display: block;
}

.schedule-tabs {
	position: relative;
}

.schedule-tabs .btns-box {
	position: relative;
	display: block;
	margin-bottom: 40px;
}

.schedule-tabs .tab-buttons {
	position: relative;
	text-align: center;
	border-bottom: none;
	display: flex;
	justify-content: center;
}

.schedule-tabs .tab-buttons li {
	position: relative;
	display: inline-block;
	height: 135px;
	width: 160px;
	border-radius: 20px;
	background-color: #ffffff;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	font-weight: 600;
	text-align: left;
	padding: 15px 15px;
	padding-top: 70px;
	padding-left: 90px;
	cursor: pointer;
	margin: 0 15px 20px;
	border: 1px solid #dddddd;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.schedule-tabs .tab-buttons li .day {
	position: absolute;
	left: 0px;
	top: 30px;
	padding: 5px 20px;
	background-color: #222222;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #ffffff;
	border-radius: 0 15px 15px 0;
}

.schedule-tabs .tab-buttons li .date {
	position: absolute;
	left: -0;
	top: 80px;
	width: 100px;
	text-align: center;
	font-size: 38px;
	line-height: 20px;
	font-weight: 600;
	color: #222222;
}

.schedule-tabs .tab-buttons li .month {
	display: block;
	font-size: 20px;
	line-height: 1em;
	font-weight: 600;
	color: #222222;
	text-transform: uppercase;
}

.schedule-tabs .tab-buttons li .year {
	font-size: 30px;
}

.schedule-tabs .tab-buttons li.active {
	box-shadow: 0 20px 30px rgba(0, 0, 0, .10);
}

.schedule-tabs .tab-buttons li:nth-child(4n + 1) .day {
	background-color: #ffc20b;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 2) .day {
	background-color: #40cbb4;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 3) .day {
	background-color: #4c35a9;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 4) .day {
	background-color: #e6275a;
}


.schedule-tabs .tab-buttons li:nth-child(4n + 1) .month,
.schedule-tabs .tab-buttons li:nth-child(4n + 1) .date {
	color: #ffc20b;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 2) .month,
.schedule-tabs .tab-buttons li:nth-child(4n + 2) .date {
	color: #40cbb4;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 3) .month,
.schedule-tabs .tab-buttons li:nth-child(4n + 3) .date {
	color: #4c35a9;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 4) .month,
.schedule-tabs .tab-buttons li:nth-child(4n + 4) .date {
	color: #e6275a;
}


.schedule-tabs .tab-buttons li:nth-child(4n + 1).active {
	box-shadow: 0 0px 30px rgba(255, 194, 11, .30);
	border: 1px solid #ffc20b;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 2).active {
	box-shadow: 0 0px 30px rgba(64, 203, 180, .30);
	border: 1px solid #40cbb4;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 3).active {
	box-shadow: 0 0px 30px rgba(76, 53, 169, .30);
	border: 1px solid #4c35a9;
}

.schedule-tabs .tab-buttons li:nth-child(4n + 4).active {
	box-shadow: 0 0px 30px rgba(230, 39, 90, .30);
	border: 1px solid #e6275a;
}

.schedule-timeline {
	position: relative;
}

/* === Schedule Block === */

.schedule-block {
	position: relative;
	margin-bottom: 30px;
}

.schedule-block:after {
	display: table;
	clear: both;
	content: "";
}

.schedule-block .inner-box {
	position: relative;
	float: right;
	width: 50%;
	text-align: left;
}

.schedule-block .inner-box .inner {
	position: relative;
	padding: 20px 30px 20px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
	margin-left: 80px;
	border-radius: 20px;
}

.schedule-block .inner:before {
	position: absolute;
	top: 0;
	bottom: -30px;
	right: 100%;
	border-left: 2px dashed #dddddd;
	content: "";
	margin-right: 80px;
}

.schedule-block .inner:after {
	position: absolute;
	right: 100%;
	top: 35px;
	border-top: 15px solid transparent;
	border-right: 20px solid #ffffff;
	border-bottom: 15px solid transparent;
	content: "";
}

.schedule-block .date {
	position: absolute;
	left: -132px;
	width: 100px;
	top: 0px;
	height: 100px;
	background-color: #ffffff;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	color: #212639;
	font-weight: 500;
	padding: 30px 15px 0;
	border-radius: 50%;
	border: 2px dashed #dddddd;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

.schedule-block .speaker-info {
	position: relative;
	padding-left: 80px;
	padding-top: 10px;
	min-height: 60px;
}

.schedule-block .speaker-info .thumb {
	position: absolute;
	left: 0;
	top: 0;
	width: 60px;
	border-radius: 50%;
	overflow: hidden;
}

.schedule-block .speaker-info .name {
	font-size: 16px;
	color: #212639;
	font-weight: 500;
	line-height: 1.2em;
}

.schedule-block .speaker-info .designation {
	font-size: 13px;
}

.schedule-block h4 {
	position: relative;
	font-size: 22px;
	line-height: 1.2em;
	color: #0f1925;
	font-weight: 600;
	margin-bottom: 15px;
	padding-top: 15px;
}

.schedule-block h4 a {
	color: #212639;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.schedule-block h4 a:hover {
	color: #8c7c20;
}

.schedule-block .text {
	position: relative;
	font-size: 16px;
	line-height: 26px;
	color: #888888;
	margin-bottom: 15px;
}

.schedule-block .btn-box {
	position: relative;
	padding: 6px 0;
}

.schedule-block .btn-box a {
	padding: 10px 20px;
	line-height: 20px;
	font-size: 14px;
	background-color: #444444;
	color: #ffffff;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	border-radius: 5px;
}

.schedule-block .btn-box a:hover {
	color: #ffffff;
}

.schedule-block.even .inner-box {
	float: left;
	text-align: right;
}

.schedule-block.even .inner-box .inner {
	margin-left: 0;
	margin-right: 80px;
}

.schedule-block.even .inner:before {
	right: auto;
	left: 100%;
	margin-left: 80px;
	margin-right: 0;
}

.schedule-block.even .inner:after {
	right: auto;
	left: 100%;
	border-right: 0;
	border-left: 20px solid #ffffff;
}

.schedule-block:last-child .inner:before {
	display: none;
}

.schedule-block.even .date {
	left: auto;
	right: -132px;
}

.schedule-block.even .speaker-info {
	padding-left: 0;
	padding-right: 80px;
}

.schedule-block.even .speaker-info .thumb {
	left: auto;
	right: 0;
}

.schedule-block:nth-child(4n + 1) .date,
.schedule-block:nth-child(4n + 1) .inner:before {
	border-color: #ffc20b;
}

.schedule-block:nth-child(4n + 2) .date,
.schedule-block:nth-child(4n + 2) .inner:before {
	border-color: #40cbb4;
}

.schedule-block:nth-child(4n + 3) .date,
.schedule-block:nth-child(4n + 3) .inner:before {
	border-color: #4c35a9;
}

.schedule-block:nth-child(4n + 4) .date,
.schedule-block:nth-child(4n + 4) .inner:before {
	border-color: #e6275a;
}

/* Background */

.schedule-block:nth-child(4n + 1) .btn-box a {
	background-color: #ffc20b;
}

.schedule-block:nth-child(4n + 1) .btn-box a:hover {
	color: #ffc20b;
	border-radius: 0px;
	background: none;
	border-color: #ffc20b;
}

.schedule-block:nth-child(4n + 2) .btn-box a {
	background-color: #40cbb4;
}

.schedule-block:nth-child(4n + 2) .btn-box a:hover {
	border-radius: 0px;
	color: #40cbb4;
	background: none;
	border-color: #40cbb4;
}

.schedule-block:nth-child(4n + 3) .btn-box a {
	background-color: #4c35a9;
}

.schedule-block:nth-child(4n + 3) .btn-box a:hover {
	border-radius: 0px;
	color: #4c35a9;
	background: none;
	border-color: #4c35a9;
}

.schedule-block:nth-child(4n + 4) .btn-box a {
	background-color: #e6275a;
}

.schedule-block:nth-child(4n + 4) .btn-box a:hover {
	border-radius: 0px;
	color: #e6275a;
	background: none;
	border-color: #e6275a;
}

/* color */

.schedule-section.style-three .schedule-block:nth-child(4n + 1) .date,
.schedule-section.style-two .schedule-block:nth-child(4n + 1) .date,
.schedule-block:nth-child(4n + 1) h4 a:hover {
	color: #ffc20b;
}

.schedule-section.style-three .schedule-block:nth-child(4n + 2) .date,
.schedule-section.style-two .schedule-block:nth-child(4n + 2) .date,
.schedule-block:nth-child(4n + 2) h4 a:hover {
	color: #40cbb4;
}

.schedule-section.style-three .schedule-block:nth-child(4n + 3) .date,
.schedule-section.style-two .schedule-block:nth-child(4n + 3) .date,
.schedule-block:nth-child(4n + 3) h4 a:hover {
	color: #4c35a9;
}

.schedule-section.style-three .schedule-block:nth-child(4n + 4) .date,
.schedule-section.style-two .schedule-block:nth-child(4n + 4) .date,
.schedule-block:nth-child(4n + 4) h4 a:hover {
	color: #e6275a;
}

/*** 

====================================================================
	schedule Section Style two
====================================================================

***/

.schedule-section.style-two {
	position: relative;
}

.schedule-section.style-two .schedule-block .date {
	position: relative;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
	height: auto;
	width: auto;
	margin-bottom: 20px;
	text-align: left;
	font-size: 16px;
}

.schedule-section.style-two .schedule-block .inner-box {
	width: 100%;
}

.schedule-section.style-two .schedule-block .inner-box .inner {
	margin: 0;
	padding: 50px 50px 40px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.schedule-section.style-two .schedule-block .inner:before,
.schedule-section.style-two .schedule-block .inner:after {
	display: none;
}

.schedule-section.style-two .schedule-timeline {
	position: relative;
	max-width: 900px;
	margin: 0 auto;
}

/*** 

====================================================================
	schedule Section Style Three
====================================================================

***/

.schedule-section.style-three {
	position: relative;
}

.schedule-tabs.style-three .tab-buttons li {
	float: left;
	width: 25%;
	margin: 0;
	box-shadow: none;
	border-radius: 0;
	border-color: transparent;
}

.schedule-tabs.style-three .tab-buttons li.active {
	z-index: 2;
}

.schedule-section.style-three .schedule-block .date {
	position: relative;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
	height: auto;
	width: auto;
	margin-bottom: 20px;
	text-align: left;
	font-size: 16px;
}

.schedule-section.style-three .schedule-block .inner-box {
	width: 100%;
}

.schedule-section.style-three .schedule-block .inner-box .inner {
	margin: 0;
	padding: 50px 50px 40px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.schedule-section.style-three .schedule-block .inner:before,
.schedule-section.style-three .schedule-block .inner:after {
	display: none;
}

.schedule-section.style-three .schedule-timeline {
	position: relative;
}

/*** 

====================================================================
		Event Detail
====================================================================

***/

.event-detail {
	position: relative;
	padding: 120px 0 90px;
}

.event-detail .auto-container {
	max-width: 1000px;
}

.event-detail .image-box {
	position: relative;
	margin-bottom: 30px;
}

.event-detail .image-box .image {
	position: relative;
	margin-bottom: 0;
}

.event-detail .image-box .image img {
	display: block;
	width: 100%;
	height: auto;
}

.event-detail .content-box {
	position: relative;
}

.event-detail .upper-info {
	position: relative;
	margin-bottom: 20px;
}

.event-detail .upper-info li {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 24px;
	color: #222222;
	font-weight: 500;
	text-transform: uppercase;
	padding-left: 25px;
	margin-right: 20px;
}

.event-detail .upper-info .icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 16px;
	line-height: 24px;
	color: #8c7c20;
}

.event-detail .content-box h2 {
	display: block;
	font-size: 32px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 700;
	margin-bottom: 25px;
}

.event-detail .speaker-info {
	position: relative;
	padding-left: 75px;
	min-height: 60px;
	margin-bottom: 25px;
}

.event-detail .speaker-info .thumb {
	position: absolute;
	left: 0;
	top: 0;
	width: 60px;
	border-radius: 50%;
	overflow: hidden;
}

.event-detail .speaker-info .name {
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
	color: #222222;
}

.event-detail .speaker-info .designation {
	font-size: 14px;
	line-height: 20px;
}

.event-detail .content-box p {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #888888;
	font-weight: 400;
	margin-bottom: 30px;
}

.event-detail .two-column {
	position: relative;
}

.event-detail .two-column .column {
	margin-bottom: 30px;
}

.event-detail .two-column .image {
	position: relative;
	margin-bottom: 0;
}

.event-detail .two-column .image img {
	display: block;
	width: 100%;
}

.event-detail .two-column h4 {
	font-size: 20px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 500;
	margin-bottom: 20px;
	margin-top: 20px;
}

/*** 

====================================================================
		Pricing Section
====================================================================

***/

.pricing-section {
	position: relative;
	padding: 120px 0 80px;
	overflow: hidden;
}

.pricing-section .outer-box {
	max-width: 1100px;
	margin: 0 auto;
}


.pricing-section .row {
	margin: 0 -30px;
}

.pricing-block {
	position: relative;
	padding: 0 30px;
	margin-bottom: 40px;
}

.pricing-block .inner-box {
	position: relative;
	background-color: #ffffff;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
	padding: 0 0 30px;
	max-width: 370px;
	margin: 0 auto;
	border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
	position: relative;
	padding: 50px 30px 0;
	background-color: #40cbb4;
	text-align: center;
}

.pricing-block .icon-box:before {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 75px;
	width: 100%;
	border-radius: 50% 50% 0 0;
	background-color: #ffffff;
	content: "";
}

.pricing-block .icon-box .icon-outer {
	position: relative;
	height: 150px;
	width: 150px;
	background-color: #ffffff;
	border-radius: 50%;
	margin: 0 auto;
	padding: 10px;
}

.pricing-block .icon-box .icon {
	position: relative;
	display: block;
	height: 130px;
	width: 130px;
	line-height: 120px;
	border: 5px solid #40cbb4;
	border-radius: 50%;
	font-size: 70px;
	color: #40cbb4;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box .icon {
	transform: rotate(360deg);
}

.pricing-block .price-box {
	position: relative;
	text-align: center;
	padding: 10px 20px;
}

.pricing-block .title {
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 600;
}

.pricing-block .price {
	display: block;
	font-size: 30px;
	color: #222222;
	font-weight: 700;
	color: #40cbb4;
}


.pricing-block .features {
	position: relative;
	max-width: 200px;
	margin: 0 auto 20px;
}

.pricing-block .features li {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #848484;
	font-weight: 500;
	padding: 5px 0;
	padding-left: 30px;
	border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li:before {
	position: absolute;
	left: 0;
	top: 5px;
	font-size: 16px;
	line-height: 30px;
	content: "\52";
	font-family: 'ElegantIcons';
	color: #2bd40f;
}

.pricing-block .features li.false:before {
	color: #8c7c20;
	content: "\51";
}

.pricing-block .features li a {
	color: #848484;
}

.pricing-block .features li:last-child {
	border-bottom: 0;
}

.pricing-block .btn-box {
	position: relative;
	text-align: center;
}

.pricing-block .btn-box a {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 25px;
	color: #ffffff;
	font-weight: 500;
	padding: 8px 30px;
	background-color: #40cbb4;
	border-radius: 10px;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 300ms ease;
}

.pricing-block .btn-box a:hover {
	color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
	color: #40cbb4;
	background: none;
	border-radius: 0px;
	border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box .icon,
.pricing-block:nth-child(2) .inner-box {
	border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
	background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
	color: #1d95d2;
	background: none;
	border-radius: 0px;
	border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box .icon,
.pricing-block:nth-child(2) .price {
	color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box .icon,
.pricing-block:nth-child(3) .inner-box {
	border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
	background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box .icon,
.pricing-block:nth-child(3) .price {
	color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
	color: #ffc20b;
	background: none;
	border-radius: 0px;
	border-color: #ffc20b;
}

.icon-circle-green {
	width: 300px;
	height: 300px;
	background-image: url(../images/icons/circle-green.png);
}

.icon-circle-orange {
	width: 300px;
	height: 300px;
	background-image: url(../images/icons/circle-green.png);
}

.icon-circle-pink {
	width: 300px;
	height: 300px;
	background-image: url(../images/icons/circle-pink.png);
}

.pricing-section .icon-circle-green {
	left: -375px;
	top: -50px;
	opacity: .50;
}

.pricing-section .icon-circle-blue {
	right: -375px;
	top: 50%;
	opacity: .50;
}

.pricing-section .icon-circle-pink {
	left: -375px;
	bottom: -100px;
	opacity: .50;
}

.pricing-section .icon-circle-green,
.pricing-section .icon-circle-blue,
.pricing-section .icon-circle-pink {
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

/*** 

====================================================================
		Pricing Section Two
====================================================================

***/

.pricing-section-two {
	position: relative;
	padding: 120px 0 70px;
	overflow: hidden;
}

.pricing-section-two.alternate {
	position: relative;
	background-color: #fafafa;
}


.pricing-section-two .outer-box {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
}

.pricing-block-two {
	position: relative;
	display: block;
	margin-bottom: 50px;
}

.pricing-block-two .inner-box {
	position: relative;
	background-color: #ffffff;
	padding: 30px 30px 25px;
	max-width: 300px;
	margin: 0 auto;
	border: 1px solid #dddddd;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.pricing-block-two .inner-box:hover {
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.07);
}

.pricing-block-two .inner-box:hover .theme-btn {
	border-radius: 0px;
}

.pricing-block-two .title {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #212639;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.pricing-block-two .title .icon {
	font-size: 22px;
	margin-right: 5px;
	display: inline-block;
}

.pricing-block-two .price-box {
	position: relative;
	margin-left: -30px;
}

.pricing-block-two .price {
	position: relative;
	display: inline-block;
	font-size: 38px;
	line-height: .9em;
	color: #ffffff;
	font-weight: 600;
	margin-bottom: 20px;
	padding: 20px 30px;
	padding-right: 30px;
	border-radius: 0 50px 50px 0;
	background: rgb(247, 0, 104);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	background-size: 600% 100%;
}

.pricing-block-two .inner-box:hover .price {
	padding-right: 80px;
}

.pricing-block-two .features {
	position: relative;
	max-width: 300px;
	margin: 0 auto 20px;
}

.pricing-block-two .features li {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #848484;
	font-weight: 500;
	padding: 5px 0;
	padding-left: 30px;
	border-bottom: 1px dashed #dddddd;
}

.pricing-block-two .features li:before {
	position: absolute;
	left: 0;
	top: 5px;
	font-size: 16px;
	line-height: 30px;
	content: "\52";
	font-family: 'ElegantIcons';
	color: #2bd40f;
}

.pricing-block-two .features li.false:before {
	color: #8c7c20;
	content: "\51";
}

.pricing-block-two .features li a {
	color: #848484;
}

.pricing-block-two .features li:last-child {
	border-bottom: 0;
}

.pricing-block-two .btn-box {
	position: relative;
	text-align: center;
}

.pricing-block-two .btn-box a {
	position: relative;
	font-size: 14px;
	line-height: 25px;
	font-weight: 500;
	padding: 8px 30px;
	border-radius: 30px;
}

.pricing-section-two .icon-line-1 {
	right: 0%;
	top: 15%;
}

.pricing-section-two .icon-dots {
	right: 0%;
	bottom: 15%;
}

.pricing-section-two .icon-circle-1 {
	left: -400px;
	bottom: -200px;
	opacity: .30;
}

.icon-shape-5 {
	width: 540px;
	height: 330px;
	background-image: url(../images/icons/shape-5.png);
	background-size: 100%;
}

/*** 

====================================================================
		Pricing Section Three
====================================================================

***/

.pricing-section-three {
	position: relative;
	padding: 120px 0 70px;
	overflow: hidden;
}

.pricing-section-three .outer-box {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
}

.pricing-block-three {
	position: relative;
	margin-bottom: 50px;
}

.pricing-block-three .inner-box {
	position: relative;
	background-color: #ffffff;
	border-radius: 30px;
	padding: 70px 20px 60px;
	text-align: center;
	max-width: 400px;
	margin: 0 auto;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.pricing-block-three .title {
	position: relative;
	display: block;
	font-size: 38px;
	line-height: 1em;
	color: #848484;
	font-weight: 200;
	text-transform: uppercase;
	padding-bottom: 30px;
	margin-bottom: 25px;
}

.pricing-block-three .title:before {
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -40px;
	width: 80px;
	background-color: #848484;
	height: 2px;
	content: "";
}

.pricing-block-three .price {
	position: relative;
	display: block;
	font-size: 100px;
	line-height: .9em;
	color: #8c7c20;
	font-weight: 200;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 20px;
}

.pricing-block-three .features {
	position: relative;
	max-width: 300px;
	margin: 0 auto 20px;
}

.pricing-block-three .features li {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #848484;
	font-weight: 500;
	padding: 5px 0;
	border-bottom: 1px dashed #dddddd;
}

.pricing-block-three .features li a {
	color: #848484;
}

.pricing-block-three .features li:last-child {
	border-bottom: 0;
}

.pricing-block-three .btn-box {
	position: relative;
	text-align: center;
}

.pricing-block-three .btn-box a {
	position: relative;
	font-size: 14px;
	line-height: 25px;
	font-weight: 500;
	padding: 8px 30px;
	border-radius: 30px;
	border: 1px solid transparent;
}

.pricing-section-three .icon-line-1 {
	right: 0%;
	top: 15%;
}

.pricing-section-three .icon-dots {
	right: 0%;
	bottom: 15%;
}

.pricing-section-three .icon-circle-1 {
	left: -400px;
	bottom: -200px;
	opacity: .30;
}

/***

====================================================================
		Fun Fact Section
====================================================================

***/

.fun-fact-section {
	position: relative;
	padding: 100px 0 50px;
}

.fun-fact-section:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../images/icons/pattern-1.jpg);
	content: "";
	opacity: .10;
}

.fun-fact-section .fact-counter {
	position: relative;
}

.fun-fact-section .counter-column {
	position: relative;
	text-align: center;
	margin-bottom: 50px;
}

.fun-fact-section .count-box {
	position: relative;
	font-size: 80px;
	line-height: 1em;
	color: #222222;
	font-weight: 700;
}

.fun-fact-section .count-box .icon {
	position: relative;
	display: inline-block;
	font-size: 60px;
	color: #ffffff;
	margin-bottom: 20px;
	height: 120px;
	width: 120px;
	line-height: 120px;
	border-radius: 50%;
	font-weight: 400;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
	background-color: #222222;
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.10);
}

.fun-fact-section .count-box:hover .icon {
	transform: rotateY(360deg);
}

.fun-fact-section .count-box .count-text {
	position: relative;
	display: block;
	font-size: 60px;
	line-height: 1em;
	color: #15212f;
	text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.10);
	font-weight: 600;
	margin-bottom: 10px;
}

.fun-fact-section .count-box .counter-title {
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 22px;
	font-weight: 700;
	color: #222222;
}

.fun-fact-section .counter-column:nth-child(4n + 1) .count-box .icon {
	background-color: #ffc20b;
}

.fun-fact-section .counter-column:nth-child(4n + 2) .count-box .icon {
	background-color: #40cbb4;
}

.fun-fact-section .counter-column:nth-child(4n + 3) .count-box .icon {
	background-color: #4c35a9;
}

.fun-fact-section .counter-column:nth-child(4n + 4) .count-box .icon {
	background-color: #e6275a;
}

.fun-fact-section .counter-column:nth-child(4n + 1) .counter-title {
	color: #ffc20b;
}

.fun-fact-section .counter-column:nth-child(4n + 2) .counter-title {
	color: #40cbb4;
}

.fun-fact-section .counter-column:nth-child(4n + 3) .counter-title {
	color: #4c35a9;
}

.fun-fact-section .counter-column:nth-child(4n + 4) .counter-title {
	color: #e6275a;
}

.fun-fact-section.style-two {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.fun-fact-section.style-two:before {
	background: #1c94d2;
	opacity: 0;
}

.fun-fact-section.style-two .count-box .count-text {
	color: #ffffff;
}

/*====================================================
		FAQ's Section
====================================================*/

.faq-section {
	position: relative;
	padding: 120px 0 90px;
}

.faq-section .content-column {
	position: relative;
	margin-bottom: 30px;
}

/* Accordion Box */

.accordion-box {
	position: relative;
}

.accordion-box .blocks {
	margin-bottom: 30px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

.accordion-box .blocks:last-child {
	margin-bottom: 0px;
}

.accordion-box .blocks .acc-btn {
	display: block;
	position: relative;
	font-size: 18px;
	cursor: pointer;
	line-height: 28px;
	color: #ffffff;
	font-weight: 400;
	border-radius: 4px;
	padding: 15px 25px 15px 25px;
	padding-right: 50px;
	border: 1px solid #dddddd;
	background-color: #40cbb4;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accordion-box .blocks .acc-btn::after {
	position: absolute;
	right: 25px;
	top: 10px;
	font-size: 18px;
	line-height: 35px;
	color: #ffffff;
	text-align: center;
	font-family: "Font Awesome 5 Free";
	content: "\f107";
	z-index: 11;
	font-weight: 900;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accordion-box .blocks .acc-btn.collapsed::after {
	content: "\f105";
}

.accordion-box .blocks .acc-content {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-top: 0;
}

.accordion-box .blocks .acc-content .content {
	position: relative;
	font-size: 16px;
	padding: 30px 35px;
}

/*** 

====================================================================
		FAQ Form Section
====================================================================

***/

.faq-form-section {
	position: relative;
	padding: 120px 0 120px;
	background-color: #fafafa;
}

.faq-form-section .faq-form {
	position: relative;
}

.faq-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.faq-form .form-group input[type="text"],
.faq-form .form-group input[type="email"],
.faq-form .form-group input[type="url"],
.faq-form .form-group textarea,
.faq-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	font-size: 15px;
	color: #222222;
	line-height: 28px;
	padding: 10px 25px;
	background-color: #ffffff;
	font-weight: 400;
	height: 50px;
	border: 1px solid #dddddd;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.faq-form .form-group input:focus,
.faq-form .form-group select:focus,
.faq-form .form-group textarea:focus {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

.faq-form .form-group textarea {
	height: 200px;
	resize: none;
	padding-top: 15px;
}

.faq-form .form-group button {
	border: 0;
	font-size: 14px;
	font-weight: 400;
	margin-top: 10px;
	border: 1px solid transparent;
}

.faq-form-section .image-column {
	position: relative;
}

.faq-form-section .image-column .image-box {
	position: relative;
}

.faq-form-section .image-column .image {
	position: relative;
	margin-bottom: 0;
}

.faq-form-section .image-column .image img {
	display: block;
}

/***

====================================================================
			Why Choose Us
====================================================================

***/

.why-choose-us {
	position: relative;
	background-color: #fafafa;
	padding: 120px 0 70px;
}

.why-choose-us .image-column {
	position: relative;
	margin-bottom: 50px
}

.why-choose-us .image-column .image-box {
	position: relative;
}

.why-choose-us .image-column .image-box .image {
	position: relative;
	margin-bottom: 0;
}

.why-choose-us .image-column .image-box img {
	display: block;
	width: 100%;
}

.why-choose-us .content-column {
	position: relative;
	margin-bottom: 50px;
}

.why-choose-us .content-column .inner-column {
	position: relative;
	padding-top: 20px;
}

.why-choose-us .content-column .sec-title {
	position: relative;
	margin-bottom: 30px;
}

.why-choose-us .content-column .list-style-one {
	margin-bottom: 30px;
}

/*** 

====================================================================
		App Section
====================================================================

***/

.app-section {
	position: relative;
	background-color: #fafafa;
	padding: 90px 0;
	z-index: 9;
}

.app-section .image-column {
	position: relative;
}

.app-section .image-column .inner-column {
	position: relative;
	text-align: right;
}

.app-section .image-column .image-box {
	position: relative;
}

.app-section .image {
	position: relative;
	margin-bottom: 0;
}

.app-section .image img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.app-section .content-column {
	position: relative;
	margin-bottom: 50px;
}

.app-section .content-column .inner-column {
	position: relative;
	padding-top: 80px;
}

.app-section .content-column .sec-title {
	margin-bottom: 30px;
}

.app-section .content-column .text-box {
	font-size: 16px;
	margin-bottom: 30px;
}

.app-section .link-box {
	position: relative;
}

.app-section .link-box a {
	position: relative;
	display: inline-block;
	margin-right: 25px;
	margin-bottom: 10px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.app-section .link-box a:hover {
	-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.30);
	-ms-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.30);
	-o-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.30);
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.30);
}

/*** 

====================================================================
		Gallery Section
====================================================================

***/

.gallery-section {
	position: relative;
	padding: 120px 0 90px;
}

.gallery-item {
	position: relative;
	margin-bottom: 30px;
}

.gallery-item .image-box {
	position: relative;
	box-shadow: 0 0px 30px rgba(8, 13, 62, .10);
	overflow: hidden;
}

.gallery-item .image-box .image {
	position: relative;
	margin-bottom: 0;
}

.gallery-item .image-box .image img {
	display: block;
	width: 100%;
	height: auto;
}

.gallery-item .overlay-box {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	content: "";
	opacity: 0;
	background-color: #1d95d2;
	-webkit-transform: scale(.5);
	-moz-transform: scale(.5);
	-ms-transform: scale(.5);
	-o-transform: scale(.5);
	transform: scale(.5);
	border-radius: 500px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-item .image-box:hover .overlay-box {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: .9;
	border-radius: 0px;
}

.gallery-item .overlay-box a {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -29px;
	margin-left: -29px;
}

.gallery-item .overlay-box a span {
	display: block;
	height: 58px;
	width: 58px;
	background-color: #8c7c20;
	color: #ffffff;
	border-radius: 50%;
	font-weight: 700;
	font-size: 18px;
	line-height: 58px;
}

.gallery-section .owl-nav {
	display: none;
}

.gallery-section.style-two {
	padding: 0;
}

.gallery-section.style-two .gallery-item {
	margin-bottom: 0;
}

/* Instagram Feed  */

.insta-feeds {
	position: relative;
	overflow: hidden;
}

.insta-feeds .overlay-box {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	padding: 5px;
	opacity: .70;
	content: "";
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.insta-feeds:hover .overlay-box {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.insta-feeds .insta-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	display: inline-block;
}

.insta-feeds .insta-image span {
	display: block;
	width: 50px;
	height: 50px;
	font-size: 20px;
}

.insta-feeds .insta-image span::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/*** 

====================================================================
		Event Info Section
====================================================================

***/

.event-info-section {
	position: relative;
	padding: 120px 0 120px;
}

.event-info-section .info-column {
	position: relative;
}

.event-info-section .info-column .inner-column {
	position: relative;
}

.event-info-section .info-column .sec-title {
	margin-bottom: 50px;
}

.event-info-section .info-column .sec-title h2 {
	font-size: 40px;
}

.event-info-tabs {
	position: relative;
}

.event-info-tabs .tab-buttons {
	position: relative;
}

.event-info-tabs .tab-btn {
	position: relative;
	float: left;
	padding: 10px 25px;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	background-color: #1d95d2;
	font-weight: 600;
	cursor: pointer;
	margin-right: 5px;
	border: 1px solid #dddddd;
	border-bottom: 0;
	margin-bottom: -2px;
	z-index: 3;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.event-info-tabs .tab-btn:hover,
.event-info-tabs .tab-btn.active {
	color: #1d95d2;
	background-color: #ffffff;
}

.event-info-tabs .tabs-content {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	padding: 30px 30px;
	box-shadow: 0 30px 20px rgba(0, 0, 0, .05);
}

.event-info-tabs .tabs-content .tab {
	min-height: 155px;
}

.event-info-tabs .tabs-content h4 {
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #3f4161;
	font-weight: 500;
	margin-bottom: 10px;
}

.event-info-tabs .tabs-content h4 .icon {
	color: #40cbb4;
	font-size: 20px;
	margin-right: 10px;
}


.event-info-tabs .tabs-content h5 {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #3f4161;
	font-weight: 500;
	margin-bottom: 20px;
}

.event-info-tabs .tabs-content .text {
	font-size: 16px;
	line-height: 28px;
	color: #888888;
	max-width: 470px;
}

.event-info-tabs .tabs-content .info-list {
	position: relative;
	margin-top: 10px;
}

.event-info-tabs .tabs-content .info-list li {
	position: relative;
	padding-left: 30px;
	font-size: 16px;
	line-height: 26px;
	color: #616977;
	font-weight: 400;
}

.event-info-tabs .tabs-content .info-list li .icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 14px;
	line-height: 26px;
	color: #8c7c20;
}

.event-info-tabs .tabs-content .info-list li a {
	color: #616977;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.event-info-tabs .tabs-content .info-list li a:hover {
	color: #f0228c;
	text-decoration: underline;
}

.event-info-section .image-column {
	position: relative;
}

.event-info-section .image-column .image {
	position: relative;
	background-color: #ffffff;
	padding-top: 60px;
	margin-bottom: 0;
}

.event-info-section .image-column .image img {
	display: block;
	width: 100%;
	height: auto;
}

.event-info-section .map-outer .map-canvas,
.event-info-section .map-outer {
	height: 100%;
	width: 100%;
}

.event-info-section .map-outer .map-canvas {
	right: 15px;
	left: 15px;
	width: auto;
}

/*** 

====================================================================
		Testimonial Section
====================================================================

***/

.testimonial-section {
	position: relative;
	padding: 120px 0;
	background-color: #fdfdfd;
}

.testimonial-section .bg-layer {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100%;
	opacity: .02;
}

.testimonial-section .sec-title {
	margin-bottom: 50px;
}

.testimonial-section .carousel-outer {
	position: relative;
	max-width: 800px;
	margin: 0 auto;
}

.testimonial-block {
	position: relative;
	text-align: center;
}

.testimonial-block .inner {
	position: relative;
}

.testimonial-block .icon {
	position: relative;
	display: inline-block;
	font-size: 40px;
	line-height: 80px;
	width: 80px;
	height: 80px;
	background-color: #40cbb4;
	color: #ffffff;
	border-radius: 50%;
	margin-bottom: 30px;
	text-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.testimonial-block .text {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 36px;
	color: #888888;
	font-weight: 400;
	max-width: 700px;
	margin: 0 auto 25px;
}

.testimonial-block .name {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 500;
	margin-bottom: 5px;
}

.testimonial-block .rating {
	position: relative;
	font-size: 15px;
	line-height: 25px;
	color: #ffc20b;
}

.testimonial-section .custom-prev-arrow,
.testimonial-section .custom-next-arrow {
	position: absolute;
	left: -80px;
	top: 50%;
	transform: translateY(-50%);
	height: 40px;
	width: 40px;
	font-size: 22px;
	color: #ffffff;
	line-height: 40px;
	margin-left: 15px;
	border-radius: 50%;
	text-align: center;
	background-color: #1c94d2;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	z-index: 1;
}

.testimonial-section .custom-next-arrow {
	left: auto;
	right: -80px;
}

/*** 

====================================================================
		News Section
====================================================================

***/

.news-section {
	position: relative;
	padding: 110px 0 80px;
	overflow: hidden;
}

.news-section.alternate {
	padding: 120px 0;
}

.news-section .styled-pagination {
	margin-top: 30px;
}

.news-block {
	position: relative;
	margin-bottom: 40px;
}

.news-block .inner-box {
	position: relative;
	background-color: #ffffff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

.news-block .image-box {
	position: relative;
}

.news-block .image {
	position: relative;
	overflow: hidden;
	margin-bottom: 0;
}

.news-block .image-box .image a:after {
	content: "";
	position: absolute;
	top: -110%;
	left: -210%;
	width: 200%;
	height: 200%;
	opacity: 0;
	background: rgba(255, 255, 255, 0.13);
	background: linear-gradient(to right,
			rgba(255, 255, 255, 0.13) 0%,
			rgba(255, 255, 255, 0.13) 77%,
			rgba(255, 255, 255, 0.5) 92%,
			rgba(255, 255, 255, 0.0) 100%);
}

.news-block .inner-box:hover .image-box .image a:after {
	opacity: 1;
	top: -20%;
	left: -30%;
	transition-property: left, top, opacity;
	transition-duration: 0.7s, 0.7s, 0.15s;
	transition-timing-function: linear;
}

.news-block .image img {
	display: block;
	width: 100%;
}

.news-block .lower-content {
	position: relative;
	padding: 20px 30px 30px;
}

.news-block .post-info {
	position: relative;
	margin-bottom: 10px;
}

.news-block .post-info li {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 24px;
	color: #777777;
	font-weight: 400;
	margin-right: 25px;
}

.news-block .post-info li span {
	margin-right: 5px;
	color: #1d95d2;
}

.news-block h4 {
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 1.4em;
	color: #222222;
	font-weight: 500;
	margin-bottom: 20px;
}

.news-block h4 a {
	color: #222222;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block h4 a:hover {
	color: #8c7c20;
}

.news-block .text {
	position: relative;
	font-size: 14px;
	line-height: 27px;
	color: #888888;
	font-weight: 400;
	margin-bottom: 20px;
}

.news-block .btn-box {
	position: relative;
}

.news-block .btn-box a {
	position: relative;
	display: inline-block;
	font-size: 12px;
	line-height: 20px;
	color: #ffffff;
	padding: 4px 15px;
	background-color: #1d95d2;
	border-radius: 2px;
	border: 1px solid #1d95d2;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .btn-box a:hover {
	background: none;
	color: #1d95d2;
	border-color: #1d95d2;
}

.news-section .icon-circle-blue {
	right: -305px;
	bottom: -120px;
	opacity: .70;
	-webkit-animation: fa-spin 10s infinite;
	-moz-animation: fa-spin 10s infinite;
	-ms-animation: fa-spin 10s infinite;
	-o-animation: fa-spin 10s infinite;
	animation: fa-spin 10s infinite;
}

.news-section .twist-line-1 {
	left: -110px;
	top: 290px;
}

.news-section .twist-line-2 {
	left: -10%;
	top: 80px;
}

.news-section .twist-line-3 {
	left: 90px;
	top: 50px;
}

/*** 

====================================================================
		Blog Sidebar
====================================================================

***/

.blog-sidebar {
	position: relative;
}

.blog-sidebar .news-block {
	margin-bottom: 50px;
}

.blog-sidebar .news-block .lower-content {
	padding: 30px 30px;
}

.blog-sidebar .news-block h4 {
	font-size: 26px;
}

/*** 

====================================================================
		Blog Detail
====================================================================

***/

.blog-single {
	position: relative;
}

.blog-single .news-block {
	margin-bottom: 0;
}

.blog-single .news-block .inner-box {
	box-shadow: none;
}

.blog-single .lower-content {
	padding: 20px 0 0;
}

.blog-single h2 {
	font-size: 28px;
	line-height: 1.2em;
	font-weight: 600;
	color: #222222;
	margin-bottom: 15px;
}

.blog-single .lower-content p {
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 30px;
	color: #888888;
}

.blog-single .lower-content blockquote {
	position: relative;
	padding: 35px 40px;
	padding-left: 95px;
	background-color: #fafafa;
	margin: 45px 0;
}

.blog-single .lower-content blockquote .icon {
	position: absolute;
	left: 40px;
	top: 40px;
	font-size: 30px;
	line-height: 1em;
	color: #8c7c20;
}

.blog-single .lower-content blockquote p {
	font-size: 20px;
	line-height: 32px;
	color: #7f8897;
	font-weight: 400;
	margin-bottom: 15px;
}

.blog-single .lower-content blockquote cite {
	position: relative;
	display: block;
	font-size: 15px;
	line-height: 30px;
	color: #8c7c20;
	font-weight: 400;
	font-style: normal;
	padding-left: 30px;
}

.blog-single .lower-content blockquote cite:before {
	position: absolute;
	left: 0;
	top: 14px;
	height: 1px;
	width: 20px;
	background-color: #8c7c20;
	content: "";
}

/* post share options */

.blog-single .post-share-options {
	position: relative;
	margin-bottom: 65px;
	border-top: 2px solid #eeeeee;
	padding-top: 30px;
}

.blog-single .post-share-options .tags {
	position: relative;
	display: inline-block;
}

.blog-single .post-share-options .tags li {
	position: relative;
	display: inline-block;
	margin-right: 5px;
}

.blog-single .post-share-options .tags a {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	border: 1px solid #dddddd;
	color: #7f8897;
	padding: 5px 20px;
	font-weight: 500;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.blog-single .post-share-options .tags a:hover {
	color: #8c7c20;
}

/* === Author Box === */

.blog-single .author-box {
	position: relative;
	padding: 40px 50px;
	background-color: #f7f7fa;
	margin-bottom: 90px;
}

.blog-single .author-box .inner-box {
	position: relative;
}

.blog-single .author-box .info-box {
	position: relative;
	padding-left: 110px;
	min-height: 80px;
	margin-bottom: 20px;
	padding-top: 15px;
}

.blog-single .author-box .thumb {
	position: absolute;
	left: 0;
	top: 0;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 10px;
}

.blog-single .author-box .thumb img {
	display: block;
	width: 100%;
}

.blog-single .author-box .name {
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #3f4161;
	font-weight: 700;
}

.blog-single .author-box .designation {
	display: block;
	font-size: 18px;
	line-height: 25px;
	color: #7f8897;
	font-weight: 400;
	margin-top: 5px;
}

.blog-single .author-box .text {
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #888888;
	font-weight: 400;
	margin-bottom: 25px;
}

.blog-single .author-box .read-more {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	color: #7f8897;
	font-weight: 500;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.blog-single .author-box .read-more .icon {
	margin-left: 7px;
	float: right;
	font-size: 20px;
	line-height: 24px;
}

.blog-single .author-box .read-more:hover {
	color: #8c7c20;
}

/*** 

====================================================================
		Styled Pagination
====================================================================

***/

.styled-pagination {
	position: relative;
}

.styled-pagination li {
	position: relative;
	display: inline-block;
	margin: 0 8px 8px 0;
}

.styled-pagination li:last-child {
	margin-right: 0;
}

.styled-pagination li a {
	position: relative;
	display: block;
	line-height: 50px;
	font-size: 18px;
	height: 50px;
	width: 50px;
	color: #222222;
	font-weight: 500;
	text-align: center;
	background: #ffffff;
	border: 1px solid #dddddd;
	border-bottom: 2px solid #8c7c20;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.styled-pagination li:hover a,
.styled-pagination li.active a {
	background-color: #8c7c20;
	color: #ffffff !important;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
}

.styled-pagination li.disabled:hover a {
	background: #fff;
	color: #222222 !important;
}

/*** 

====================================================================
			Coming Soon
====================================================================

***/

.coming-soon {
	position: relative;
	padding: 100px 0;
}

.coming-soon:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/pattern-1.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	content: "";
	opacity: .05;
}

.coming-soon .content {
	position: relative;
	text-align: center;
}

.coming-soon .content .logo {
	position: relative;
	margin-bottom: 30px;
}

.coming-soon .timer {
	position: relative;
	display: inline-block;
	margin-bottom: 50px;
}

.coming-soon .cs-countdown {
	position: relative;
	text-align: center;
	font-family: 'Orbitron', sans-serif;
}

.coming-soon .cs-countdown div {
	position: relative;
	display: inline-block;
	font-size: 45px;
	line-height: 1.2em;
	color: #e6275a;
	width: auto;
	font-weight: 600;
	letter-spacing: 0;
	text-shadow: 0 15px 20px rgba(0, 0, 0, 0.20);
	border-radius: 50%;
}

.coming-soon .cs-countdown h6 {
	position: relative;
	display: block;
	line-height: 30px;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: transparent;
	border-radius: 50%;
	color: #e6275a;
	width: 100px;
	margin: 0 20px 30px;
	text-shadow: 0 10px 20px rgba(0, 0, 0, 0.20);
}

.coming-soon .cs-countdown div:nth-child(2) h6,
.coming-soon .cs-countdown div:nth-child(2) {
	color: #40cbb4;
}

.coming-soon .cs-countdown div:nth-child(3) h6,
.coming-soon .cs-countdown div:nth-child(3) {
	color: #4c35a9;
}

.coming-soon .cs-countdown div:nth-child(4) h6,
.coming-soon .cs-countdown div:nth-child(4) {
	color: #1c94d2;
}

.coming-soon .cs-countdown div:nth-child(5) h6,
.coming-soon .cs-countdown div:nth-child(5) {
	color: #333333;
}

.coming-soon h1 {
	position: relative;
	color: #222222;
	font-size: 75px;
	font-weight: 700;
	line-height: 1em;
	margin-bottom: 30px;
	text-shadow: 0 10px 20px rgba(0, 0, 0, 0.20);
}

.coming-soon .content .text {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	color: #888888;
	margin-bottom: 50px;
}

.coming-soon .emailed-form .form-group {
	position: relative;
	display: block;
	max-width: 460px;
	margin: 0 auto;
	width: 100%;
	margin-bottom: 35px;
	box-shadow: 0 8px 30px rgba(72, 69, 224, .20);
	border-radius: 50px;
}

.coming-soon .emailed-form .form-group input[type="text"],
.coming-soon .emailed-form .form-group input[type="tel"],
.coming-soon .emailed-form .form-group input[type="email"],
.coming-soon .emailed-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 20px;
	height: 50px;
	font-size: 15px;
	color: #222222;
	overflow: hidden;
	padding: 15px 70px 15px;
	background: #ffffff;
	border-radius: 50px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input[type="submit"],
.coming-soon .emailed-form button {
	position: absolute;
	right: 35px;
	top: 10px;
	line-height: 30px;
	font-size: 18px;
	color: #000000;
	font-weight: 400;
	background-color: transparent;
	cursor: pointer;
}

.coming-soon .emailed-form .form-group input[type="submit"]:hover,
.coming-soon .emailed-form button:hover {
	color: #8c7c20;
}

.coming-soon .icon-circle-blue {
	left: -80px;
	bottom: -80px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

.coming-soon .icon-dots {
	left: 15%;
	top: 20%;
}

.coming-soon .icon-line-1 {
	right: 15%;
	bottom: 20%;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

.coming-soon .icon-circle-1 {
	right: -200px;
	top: -200px;
	opacity: .20;
}

/*** 

====================================================================
		Video Section
====================================================================

***/

.video-section {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 120px 0 140px;
	background-attachment: fixed;
}

.video-section:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	opacity: .70;
	content: "";
}

.video-section .content-box {
	position: relative;
	text-align: center;
}

.video-section .content-box .text {
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 26px;
	color: #ffbc00;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.video-section .content-box h2 {
	display: block;
	font-size: 48px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
}

.video-section .play-now {
	position: relative;
	display: inline-block;
	margin-top: 50px;
}

.video-section .play-now .icon {
	height: 100px;
	width: 100px;
	line-height: 100px;
	background-color: #ffbc00;
	padding-left: 0;
	font-size: 50px;
}

.play-now {
	position: relative;
	display: block;
	z-index: 9;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.play-now .icon {
	position: relative;
	display: inline-block;
	height: 80px;
	width: 80px;
	text-align: center;
	line-height: 80px;
	background-color: #000000;
	color: #ffffff;
	z-index: 1;
	padding-left: 5px;
	font-size: 24px;
	display: block;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
	-moz-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
	-ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
	-o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
	box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
	-webkit-transform-origin: center;
	-moz-transform-origin: center;
	-ms-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 70px;
	width: 70px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.play-now .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.play-now .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

/*** 

====================================================================
		Call to Action
====================================================================

***/

.call-to-action {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0;
}

.call-to-action:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #000000;
	opacity: .50;
	content: "";
}

.call-to-action .content-box {
	position: relative;
	text-align: center;
}

.call-to-action .content-box .text {
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 26px;
	color: #ffbc00;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.call-to-action .content-box h2 {
	display: block;
	font-size: 42px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 30px;
}

.call-to-action .content-box .btn-box {
	position: relative;
}

/*** 

====================================================================
		Register Section
====================================================================

***/

.register-section {
	position: relative;
	padding: 120px 0;
	/* background-color: #fafafa; */
	overflow: hidden;
}

.register-section .outer-box {
	position: relative;
	/* box-shadow: 0 80px 50px rgba(0, 0, 0, 0.10); */
	background-color: #ffffff;
}

.register-section .title-column {
	position: relative;
	background-image: url(../images/resource/promo1.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 400px;
  	/* text-shadow: 2px 2px black; */
	/* filter: grayscale(100%); */
}

.register-section .title-column2 {
	position: relative;
	background-image: url(../images/resource/promo2.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 400px;
  	/* text-shadow: 2px 2px black; */
	/* filter: grayscale(100%); */
}

.register-section .title-column3 {
	position: relative;
	background-image: url(../images/resource/promo3.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 400px;
  	/* text-shadow: 2px 2px black; */
	/* filter: grayscale(100%); */
}

.register-section .title-column .inner {
	position: relative;
	text-align: center;
	padding: 500px 50px 20px;
}

.register-section .title-column .icon-box {
	position: relative;
	display: inline-block;
	margin-bottom: 30px;
	background-color: #ffffff;
	height: 150px;
	width: 150px;
	line-height: 150px;
	border-radius: 50%;
}

.register-section .title-column .icon {
	position: relative;
	display: inline-block;
	font-size: 80px;
	/* color: #222222; */
}

.register-section .title-column .text {
	margin-top: 20px;
}

.register-section .title-column h2 {
	position: relative;
	font-size: 34px;
	display: block;
	/* background-color: rgba(0, 0, 0, 0.60); */
}

.register-section .title-column .text {
	font-size: 16px;
	line-height: 26px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.60);
}

.register-form {
	position: relative;
}

.register-form .form-inner {
	position: relative;
	padding: 50px 30px 30px;
	background-color: #000;
}

.register-form h2 {
	position: relative;
	display: block;
	font-size: 34px;
	line-height: 1.2em;
	color: #181e44;
	font-weight: 600;
	margin-bottom: 25px;
}

.register-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.register-form .form-group .icon {
	position: absolute;
	left: 20px;
	top: 10px;
	line-height: 33px;
	color: #aaaaaa;
	font-size: 16px;
	z-index: 9;
}

.register-form .form-group input[type="text"],
.register-form .form-group input[type="email"],
.register-form .form-group input[type="url"],
.register-form .form-group input[type="number"],
.register-form .form-group input[type="date"],
.register-form .form-group input[type="email"],
.register-form .form-group textarea,
.register-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 33px;
	padding: 10px 20px;
	height: 52px;
	color: #777777;
	font-size: 15px;
	background-color: #ffffff;
	border: 1px solid #aaaaaa;
	padding-left: 50px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.register-form .form-group input[type="text"]:focus,
.register-form .form-group input[type="email"]:focus,
.register-form .form-group textarea:focus {
	border-color: #f70068;
}

.register-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 33px;
	padding: 10px 20px;
	height: 200px;
	padding-left: 50px;
}

.register-form button {
	cursor: pointer;
	line-height: 25px;
	font-size: 14px;
	padding: 10px 30px;
	border: 1px solid transparent;
}

.register-section .icon-circle-3 {
	right: -100px;
	top: -120px;
	opacity: .20;
}

.register-section .icon-shape-3 {
	right: 5%;
	top: 0%;
}

/*** 

====================================================================
		Contact Page Section
====================================================================

***/

.contact-page-section {
	position: relative;
	padding: 110px 0 70px;
}

.contact-page-section .form-column {
	position: relative;
	margin-bottom: 40px;
}

.contact-page-section .form-column .inner-column {
	position: relative;
}

.contact-page-section .sec-title {
	margin-bottom: 30px;
}

.contact-page-section .sec-title h2 {
	font-size: 32px;
}

.contact-form .form-group {
	position: relative;
	margin-bottom: 30px;
}

.contact-form .form-group:last-child {
	margin-bottom: 0;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="email"],
.contact-form .form-group input[type="number"],
.contact-form .form-group input[type="url"],
.contact-form .form-group textarea,
.contact-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	font-size: 18px;
	color: #555555;
	line-height: 32px;
	padding: 15px 30px;
	border: 1px solid #f1f1f1;
	background-color: #f1f1f1;
	font-weight: 400;
	height: 64px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
	border-color: #8c7c20;
}

.contact-form .form-group textarea {
	height: 156px;
	resize: none;
}

.contact-form .form-group button {
	text-transform: capitalize;
	border: 1px solid transparent;
}

.contact-form input.error:focus,
.contact-form select.error:focus,
.contact-form textarea.error:focus {
	border-color: #ff0000;
}

.contact-form label.error {
	display: block;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	line-height: 24px;
	color: #ff0000;
	padding-top: 7px;
	margin-bottom: 0;
}

.contact-page-section .contact-column {
	position: relative;
	margin-bottom: 30px;
}

.contact-page-section .contact-info {
	position: relative;
	margin-bottom: 37px;
}

.contact-page-section .contact-info li {
	position: relative;
	padding-left: 55px;
	margin-bottom: 30px;
}

.contact-page-section .contact-info li p {
	display: block;
	font-size: 14px;
	line-height: 24px;
	color: #777777;
	font-weight: 400;
	margin: 0;
}

.contact-page-section .contact-info li strong {
	display: block;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	font-weight: 600;
}

.contact-page-section .contact-info li .icon {
	position: absolute;
	left: 0;
	top: 0;
	height: 40px;
	width: 40px;
	text-align: center;
	font-size: 18px;
	line-height: 40px;
	background-color: #1c94d2;
	color: #ffffff;
}

.contact-page-section .contact-info li a {
	color: #777777;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-page-section .contact-info li a:hover {
	color: #8c7c20;
}

/*** 

====================================================================
		Map Section
====================================================================

***/

.map-section {
	position: relative;
	display: block;
	padding-bottom: 120px;
}

.map-area {
	position: relative;
}

.map-data {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.8em;
}

.map-data a {
	display: block;
}

.map-data h6 {
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 5px;
	color: #121212;
}

.map-section .map-canvas {
	height: 540px;
	background-color: #f9f9f9;
}

/*** 

====================================================================
		Subscribe Section
====================================================================

***/

.subscribe-section {
	position: relative;
	background-color: #ffffff;
}

.subscribe-section .title-column {
	position: relative;
	margin-bottom: 40px;
}

.subscribe-section:before {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 50%;
	width: 100%;
	background-color: #101130;
	content: "";
}

.subscribe-section .content-box {
	position: relative;
	z-index: 9;
	padding: 40px 20px 0;
	border-radius: 20px;
	overflow: hidden;
	background-color: #40cbb4;
	text-align: center;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.30);
}

.subscribe-section .content-box:before {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/pattern-2.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	content: "";
	-webkit-transform: scaleY(-1);
	-moz-transform: scaleY(-1);
	-ms-transform: scaleY(-1);
	-o-transform: scaleY(-1);
	transform: scaleY(-1);
}

.subscribe-section .content-box .sec-title {
	margin-bottom: 0px;
	padding-left: 90px;
	text-align: left;
}

.subscribe-section .content-box .sec-title h2 {
	position: relative;
	display: block;
	color: #ffffff;
	font-size: 30px;
	line-height: 35px;
}

.subscribe-section .content-box .sec-title .icon {
	position: absolute;
	left: 0;
	top: 0px;
	height: 70px;
	width: 70px;
	background-color: #ffffff;
	border-radius: 50%;
	color: #8c7c20;
	line-height: 70px;
	font-size: 28px;
	text-align: center;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
	color: #1d95d2;
}

.subscribe-section .form-column {
	position: relative;
	margin-bottom: 40px;
}

.subscribe-section .newsletter-form {
	position: relative;
	max-width: 870px;
	margin: 0 auto;
}

.subscribe-section .newsletter-form .form-group {
	position: relative;
	padding-right: 140px;
	overflow: hidden;
	border-radius: 10px;
	margin-bottom: 0;
}

.subscribe-section .newsletter-form .form-group input[type="text"],
.subscribe-section .newsletter-form .form-group input[type="email"] {
	display: block;
	height: 70px;
	width: 100%;
	font-size: 16px;
	line-height: 30px;
	color: #616977;
	font-weight: 400;
	padding: 20px 40px;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.subscribe-section .newsletter-form .form-group .theme-btn {
	position: absolute;
	right: 0px;
	top: 0px;
	min-width: 140px;
	line-height: 30px;
	padding: 20px 30px;
	text-transform: capitalize;
	font-weight: 500;
	border-radius: 0;
	cursor: pointer;
}

/*** 

====================================================================
	Newsletter Section
====================================================================

***/

.newsletter-section {
	position: relative;
	padding: 120px 0;
	z-index: 1;
}


.newsletter-section .icon-shape-3 {
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	top: 0px;
	left: -110px;
	opacity: .70;
}

.newsletter-section .icon-line-1 {
	right: -70px;
	top: 50%;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

.subscribe-form {
	position: relative;
}

.subscribe-form .form-inner {
	position: relative;
	max-width: 60%;
	width: 100%;
	margin: 0 auto;
}

.subscribe-form .upper-box {
	position: relative;
	text-align: center;
}

.subscribe-form .sec-title {
	margin-bottom: 40px;
}

.subscribe-form .sec-title .text {
	margin-top: 20px;
}

.subscribe-form .upper-box .icon-box {
	position: relative;
	display: inline-block;
	height: 150px;
	width: 150px;
	border-radius: 50%;
	background: rgb(247, 0, 104);
	background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 100%);
	background: linear-gradient(to left, rgba(19, 47, 140, 0.8) 0%, rgb(126, 112, 6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
	z-index: 1;
	font-size: 70px;
	line-height: 150px;
	color: #ffffff;
	margin-bottom: 30px;
}

.subscribe-form .form-group {
	position: relative;
	display: block;
	margin: 0px;
	width: 100%;
	padding-right: 70px;
	background: #ffffff;
	z-index: 2;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="tel"],
.subscribe-form .form-group input[type="email"],
.subscribe-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 30px;
	height: 60px;
	font-size: 15px;
	color: #222222;
	background: #ffffff;
	padding: 14px 30px;
	border: 1px solid transparent;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.subscribe-form .form-group input[type="submit"],
.subscribe-form .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 60px;
	width: 60px;
	text-align: center;
	line-height: 30px;
	font-size: 18px;
	line-height: 60px;
	background-color: #ffffff;
	color: #222222;
	cursor: pointer;
}

/*** 

====================================================================
		Clients Section
====================================================================

***/

.clients-section {
	position: relative;
	padding: 120px 0 90px;
}

.clients-section:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/icon-dots-2.png);
	content: "";
	opacity: .02;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
}


.clients-section .sponsors-outer {
	position: relative;
	margin-bottom: 50px;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav {
	display: none;
}

.clients-section .sponsors-outer .client-block {
	position: relative;
	margin-bottom: 30px;
}

.clients-section .sponsors-outer .image-box {
	position: relative;
	text-align: center;
	margin-bottom: 0;
	border: 1px solid #dddddd;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 500ms ease;
}

.clients-section .sponsors-outer .image-box:hover {
	box-shadow: none;
}


.clients-section .sponsors-outer .image-box img {
	max-width: 50%;
	width: auto;
	display: inline-block;
	opacity: 1;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.clients-section .sponsors-outer .image-box:hover img {
	opacity: .70;
}

.clients-section .sponsors-outer h3 {
	display: inline-block;
	font-size: 20px;
	line-height: 1.2em;
	background-color: #8c7c20;
	color: #ffffff;
	padding: 10px 30px;
	font-weight: 700;
	margin-bottom: 20px;
	border-radius: 10px;
}

.clients-section .sponsors-outer:nth-child(3) h3 {
	background-color: #1d95d2;
}

.clients-section .sponsors-outer:nth-child(2) h3 {
	background-color: #40cbb4;
}


.clients-section .icon-dots-3 {
	left: -60px;
	top: 230px;
}

.clients-section .icon-circle-blue {
	left: -370px;
	top: 500px;
	opacity: .50;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

/*** 

====================================================================
		Clients Section Two
====================================================================

***/

.clients-section-two {
	position: relative;
	padding: 60px 0;
}

.clients-section-two:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/icon-dots-2.png);
	content: "";
	opacity: .02;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
}


.clients-section-two .sponsors-outer {
	position: relative;
}

.clients-section-two .sponsors-outer .owl-dots,
.clients-section-two .sponsors-outer .owl-nav {
	display: none;
}

.clients-section-two .sponsors-outer .client-block {
	position: relative;
}

.clients-section-two .sponsors-outer .image-box {
	position: relative;
	text-align: center;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 500ms ease;
}

.clients-section-two .sponsors-outer .image-box:hover {
	box-shadow: none;
}

.clients-section-two .sponsors-outer .image-box img {
	opacity: .80;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.clients-section-two .sponsors-outer .image-box:hover img {
	opacity: 1;
}

/*** 

====================================================================
		Clients Section Three
====================================================================

***/

.clients-section-three {
	position: relative;
	padding: 60px 0;
}

.clients-section-three:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/icon-dots-2.png);
	content: "";
	opacity: .02;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform: scale(-1);
	-moz-transform: scale(-1);
	-ms-transform: scale(-1);
	-o-transform: scale(-1);
	transform: scale(-1);
}

.clients-section-three .sponsors-outer {
	position: relative;
}

.clients-section-three .sponsors-outer .owl-dots,
.clients-section-three .sponsors-outer .owl-nav {
	display: none;
}

.clients-section-three .sponsors-outer .client-block {
	position: relative;
}

.clients-section-three .sponsors-outer .image-box {
	position: relative;
	text-align: center;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 500ms ease;
}

.clients-section-three .sponsors-outer .image-box:hover {
	box-shadow: none;
}

.clients-section-three .sponsors-outer .image-box img {
	max-width: 70%;
	opacity: .80;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.clients-section-three .sponsors-outer .image-box:hover img {
	opacity: 1;
}

/***

==================================================================
		Main Footer
==================================================================

***/

.main-footer {
	position: relative;
	background-color: #101130;
	/* background-image: url(../images/resource/banner1.jpeg); */
}

.main-footer .widgets-section {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 120px 0 50px;
}

.main-footer .footer-column {
	position: relative;
	margin-bottom: 40px;
}

.main-footer .footer-widget {
	position: relative;
}

.main-footer .footer-column .widget-title {
	position: relative;
	font-size: 24px;
	font-weight: 500;
	color: #ffffff;
	line-height: 30px;
	padding-bottom: 10px;
	margin-bottom: 30px;
}

.main-footer .footer-column .widget-title:before {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 1px;
	width: 40px;
	background-color: #f70068;
	content: '';
}

.main-footer .about-widget {
	position: relative;
}

.main-footer .logo {
	position: relative;
	margin-bottom: 5px;
}

.main-footer .footer-logo figure {
	margin: 0;
}

.main-footer .footer-logo img {
	display: block;
	max-width: 100%;
}

.main-footer .about-widget .text {
	position: relative;
	margin-bottom: 30px;
}

.main-footer .about-widget .text p {
	font-size: 14px;
	line-height: 24px;
	color: #aaaaaa;
	font-weight: 400;
	margin-bottom: 30px;
}

/* Recent Posts */

.recent-posts {
	position: relative;
	padding-left: 40px;
}

.recent-posts .post {
	position: relative;
	min-height: 90px;
	margin-bottom: 30px;
}

.recent-posts .post:last-child {
	margin-bottom: 0;
}

.recent-posts .post .thumb {
	position: absolute;
	left: 0;
	top: 0;
	height: 90px;
	width: 90px;
	overflow: hidden;
}

.recent-posts .post .thumb a {
	display: block;
}

.recent-posts .post .thumb img {
	display: block;
	width: 100%;
	height: auto;
}

.recent-posts .post h4 {
	position: relative;
	font-size: 18px;
	color: #ffffff;
	line-height: 30px;
	font-weight: 500;
	margin-bottom: 5px;
}

.recent-posts .post h4 a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.recent-posts .post h4 a:hover {
	color: #f70068;
}

.recent-posts .post .date {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #bbbbbb;
}

.recent-posts .post .date span {
	color: #f70068;
	margin-right: 4px;
	opacity: .70;
}

/* === User LInks === */

.widget-ps-50 {
	position: relative;
	padding-left: 50px;
}

.user-links li {
	position: relative;
	display: block;
	margin-bottom: 5px;
}

.user-links li a {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	padding: 0 15px;
	border-left: 1px solid #f70068;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.user-links li a:hover {
	color: #f70068;
}

.main-footer .contact-list {
	position: relative;
}

.main-footer .contact-list li {
	position: relative;
	padding-left: 40px;
	margin-bottom: 25px;
}

.main-footer .contact-list li:last-child {
	margin-bottom: 0;
}

.main-footer .contact-list li .icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 23px;
	line-height: 25px;
	color: #ffffff;
}

.main-footer .contact-list li .text {
	display: block;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
	font-weight: 400;
}

.main-footer .contact-list li .text a {
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .contact-list li .text a:hover {
	color: #f70068;
}

/* Instagram Widget */

.instagram-widget {
	position: relative;
}

.instagram-widget .link-box {
	position: absolute;
	right: 30px;
	top: 27px;
}

.instagram-widget .link-box a {
	display: inline-block;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: #f70068;
	color: #ffffff;
	font-size: 18px;
	text-align: center;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.instagram-widget .link-box a:hover {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
	background-color: #ffffff;
	color: #f70068;
}

.instagram-widget .outer {
	position: relative;
	margin: 0px -5px 0;
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 5px;
}

.instagram-widget .outer .image {
	position: relative;
}

.instagram-widget .image img {
	display: block;
	width: 100%;
}

.instagram-widget .image a {
	position: relative;
	display: block;
}

.instagram-widget .image a:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	padding: 5px;
	opacity: .70;
	content: "";
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.instagram-widget .image a:hover:before {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.instagram-widget .image a:after {
	position: absolute;
	height: 20px;
	width: 20px;
	left: 50%;
	top: 50%;
	margin-left: -10px;
	margin-top: -10px;
	text-align: center;
	line-height: 20px;
	font-size: 18px;
	color: #f70068;
	font-weight: 900;
	content: "\f0c1";
	font-family: "Font Awesome 5 Free";
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.instagram-widget .image a:hover:after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* === Footer Bottom === */

.main-footer .footer-bottom {
	position: relative;
	width: 100%;
	background-color: #1e1f36;
	text-align: center;
}

.main-footer .footer-bottom .inner-container {
	position: relative;
}

.main-footer .footer-bottom .copyright-text {
	position: relative;
	padding: 20px 0;
}

.main-footer .footer-bottom .copyright-text p {
	position: relative;
	line-height: 20px;
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 0;
}

.main-footer .footer-bottom .copyright-text a {
	color: #ffffff;
	font-weight: 600;
	text-decoration: underline;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .footer-bottom .copyright-text a:hover {
	color: #f70068;
}

.main-footer .footer-bottom .footer-nav {
	position: relative;
	float: right;
}

.main-footer .footer-bottom .footer-nav ul {
	position: relative;
	padding: 15px 0;
}

.main-footer .footer-bottom .footer-nav ul li {
	position: relative;
	float: left;
	margin-left: 10px;
	font-family: "Oswald", sans-serif;
}

.main-footer .footer-bottom .footer-nav ul li a {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
	padding: 5px 10px;
	text-transform: uppercase;
	background-color: #333333;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .footer-bottom .footer-nav ul li a:hover {
	color: #f70068;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.20);
}

/***

==================================================================
		Main Footer Style Two
==================================================================

***/

.main-footer.style-two {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	text-align: center;
}

.main-footer.style-two .footer-content {
	position: relative;
	padding: 70px 0 70px;
}

.main-footer.style-two .footer-logo {
	position: relative;
	text-align: center;
	margin-bottom: 20px;
}

.main-footer.style-two .footer-logo img {
	display: inline-block;
}

.main-footer.style-two .text {
	position: relative;
	max-width: 700px;
	margin: 0 auto;
	font-size: 16px;
	color: #ffffff;
	line-height: 28px;
	margin-bottom: 30px;
}

.main-footer .footer-nav {
	position: relative;
	margin-bottom: 30px;
}

.main-footer .footer-nav li {
	position: relative;
	display: inline-block;
	margin: 0 15px;
}

.main-footer .footer-nav li a {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 500;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .footer-nav li a:hover {
	color: #8c7c20;
}

.main-footer.style-two .copyright-text {
	color: #ffffff;
	margin-bottom: 30px;
}

.main-footer.style-two .copyright-text a {
	color: #ffffff;
}

.main-footer.style-two .copyright-text a:hover {
	color: #8c7c20;
}

.main-footer.style-two .copyright-text {
	border-top: 0;
}

/***

==================================================================
		Main Footer Style three
==================================================================

***/

.main-footer.style-three {
	position: relative;
	background: #ffffff;
	text-align: center;
	overflow: hidden;
}

.main-footer.style-three .footer-content {
	position: relative;
	padding: 70px 0 70px;
}

.main-footer.style-three .footer-logo {
	position: relative;
	text-align: center;
	margin-bottom: 20px;
}

.main-footer.style-three .footer-logo img {
	display: inline-block;
}

.main-footer.style-three .text {
	position: relative;
	max-width: 700px;
	margin: 0 auto;
	font-size: 16px;
	color: #101130;
	line-height: 28px;
	margin-bottom: 30px;
}

.main-footer.style-three .footer-nav li a {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #101130;
	font-weight: 500;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer.style-three .footer-nav li a:hover {
	color: #8c7c20;
}

.main-footer.style-three .copyright-text {
	color: #101130;
	margin-bottom: 30px;
}

.main-footer.style-three .copyright-text a {
	color: #101130;
}

.main-footer.style-three .copyright-text a:hover {
	color: #8c7c20;
}

.main-footer.style-three .copyright-text {
	border-top: 0;
}


.main-footer.style-three .icon-circle-blue {
	left: -150px;
	bottom: -100px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

.main-footer.style-three .icon-dots {
	left: 15%;
	top: 20%;
}

.main-footer.style-three .icon-circle-1 {
	right: -200px;
	top: -200px;
	opacity: .05;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
	position: relative;
	text-align: center;
	padding: 150px 0px;
}

.error-section:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url(../images/icons/pattern-1.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	content: "";
	opacity: .05;
}

.error-section .error-title {
	position: relative;
	color: #8c7c20;
	font-size: 200px;
	font-weight: 700;
	line-height: 1em;
	text-shadow: 0 20px 30px rgba(0, 0, 0, 0.20);
}

.error-section h4 {
	color: #394963;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 20px;
}

.error-section .text {
	color: #888888;
	font-size: 22px;
	line-height: 1.4em;
	margin-bottom: 30px;
}

.error-section .theme-btn {
	border-radius: 5px;
	font-weight: 500;
	margin: 0 10px 10px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.20);
}

.error-section .icon-circle-blue {
	left: -80px;
	bottom: -80px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

.error-section .icon-dots {
	left: 15%;
	top: 20%;
}

.error-section .icon-line-1 {
	right: 15%;
	bottom: 20%;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite alternate;
	-moz-animation: fa-spin 30s infinite alternate;
	-ms-animation: fa-spin 30s infinite alternate;
	-o-animation: fa-spin 30s infinite alternate;
	animation: fa-spin 30s infinite alternate;
}

.error-section .icon-circle-1 {
	right: -200px;
	top: -200px;
	opacity: .20;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
	position: relative;
	padding: 120px 0px 70px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
	margin-bottom: 50px;
}

.sidebar-page-container .sidebar.padding-left {
	padding-left: 40px;
}

.sidebar-widget {
	position: relative;
	margin-bottom: 50px;
}

.sidebar-widget:last-child {
	margin-bottom: 0;
}

/* Sidebar Title */

.sidebar-title {
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #333333;
	font-weight: 700;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 2px solid #eeeeee;
}

/* Search Box Widget */

.sidebar .search-box {
	margin-bottom: 60px;
}

.sidebar .search-box .form-group {
	position: relative;
	margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
	position: relative;
	padding: 20px 50px 20px 30px;
	border: 2px solid #eeeeee;
	background: #ffffff;
	display: block;
	font-size: 15px;
	line-height: 18px;
	width: 100%;
	height: 60px;
	color: #333333;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.sidebar .search-box .form-group input[type="text"]:focus,
.sidebar .search-box .form-group input[type="search"]:focus {
	color: #666666;
}

.sidebar .search-box .form-group button {
	position: absolute;
	right: 0;
	top: 0;
	height: 60px;
	width: 60px;
	display: block;
	font-size: 18px;
	color: #888888;
	line-height: 60px;
	font-weight: normal;
	background: #eeeeee;
	z-index: 9;
	cursor: pointer;
}

.sidebar .search-box .form-group button:hover {
	color: #8c7c20;
}

/* Post Widget */

.sidebar .popular-posts .post {
	position: relative;
	margin-bottom: 20px;
}

.sidebar .popular-posts .post .post-inner {
	position: relative;
	padding-left: 110px;
	min-height: 90px;
}

.sidebar .popular-posts .post .post-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 90px;
}

.sidebar .popular-posts .post .post-thumb img {
	display: block;
	width: 100%;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sidebar .popular-posts .post .text {
	position: relative;
	font-size: 18px;
	line-height: 26px;
	font-weight: 600;
	color: #888888;
	margin: 0px;
}

.sidebar .popular-posts .post .text a {
	color: #3f4161;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sidebar .popular-posts .post a:hover {
	color: #8c7c20;
}

.sidebar .popular-posts .post-info {
	position: relative;
	font-size: 16px;
	color: #7f8897;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 5px;
}

/* Blog Categories */

.blog-categories {
	position: relative;
}

.blog-categories li {
	position: relative;
}

.blog-categories li a {
	position: relative;
	display: block;
	color: #333333;
	font-size: 18px;
	line-height: 30px;
	font-weight: 400;
	margin-bottom: 10px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.blog-categories li a span {
	float: right;
}


.blog-categories li a:hover {
	color: #8c7c20;
}

/* Popular Tags */

.sidebar .popular-tags .widget-content {
	position: relative;
}

.sidebar .popular-tags a {
	position: relative;
	float: left;
	padding: 5px;
	margin: 0 20px 10px 0;
	color: #333333;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	background: none;
	font-weight: 400;
	border-bottom: 2px solid #dddddd;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.sidebar .popular-tags a:hover {
	border-color: #8c7c20;
	color: #8c7c20;
}

/*** 

====================================================================
		Comment Area
====================================================================

 ***/

.group-title {
	position: relative;
	margin-bottom: 40px;
}

.group-title h3 {
	position: relative;
	display: inline-block;
	font-size: 28px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 500;
}

.comments-area {
	position: relative;
	margin-bottom: 70px;
}

.comments-area .comment-box {
	position: relative;
	margin-bottom: 50px;
}

.comments-area .comment-box.reply-comment {
	margin-left: 70px;
}

.comments-area .comment-box:last-child {
	margin-bottom: 0;
}

.comments-area .comment {
	position: relative;
	min-height: 50px;
	padding-left: 70px;
}

.comments-area .comment-box .author-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 50px;
	width: 50px;
	margin-bottom: 20px;
	border-radius: 50%;
	overflow: hidden;
	background-color: #7d7d7d;
}

.comments-area .comment-box .author-thumb img {
	width: 100%;
	display: block;
}

.comments-area .comment-info {
	position: relative;
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 25px;
	font-weight: 700;
	color: #12114a;
}

.comments-area .comment-box .name {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 25px;
	font-weight: 700;
	color: #12114a;
	margin-right: 10px;
}

.comments-area .comment-box .date {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 24px;
	color: #777777;
	font-weight: 400;
	margin-left: 10px;
}

.comments-area .comment-box .text {
	font-size: 15px;
	line-height: 26px;
	color: #888888;
	font-weight: 400;
}

.comments-area .comment-box .reply-btn {
	position: relative;
	margin-top: 20px;
	font-size: 14px;
	line-height: 20px;
	padding: 5px 20px;
}

/*** 

====================================================================
		Comment Form
====================================================================

 ***/

.comment-form {
	position: relative;
}

.comment-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.comment-form .form-group:last-child {
	margin-bottom: 0;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="email"],
.comment-form .form-group textarea,
.comment-form .form-group select {
	position: relative;
	display: block;
	height: 50px;
	width: 100%;
	font-size: 14px;
	color: #878787;
	line-height: 28px;
	font-weight: 400;
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #999999;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.comment-form .form-group input:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
	border-color: #12114a;
}

.comment-form .form-group textarea {
	height: 190px;
	resize: none;
}

.comment-form .form-group input[type="submit"],
.comment-form .form-group button {
	font-size: 15px;
	padding: 10px 30px;
	font-weight: 500;
	cursor: pointer;
	border: 1px solid transparent;
}

/*=========================================================
			Buy Tickets
=========================================================*/

.buy-ticket {
	position: relative;
	padding: 0px 0 100px;
}

.buy-ticket .form-column {
	position: relative;
	margin-bottom: 30px;
}

.buy-ticket .form-column .inner-column {
	position: relative;
	padding-right: 30px;
}

.buy-ticket .content-column {
	position: relative;
	margin-bottom: 20px;
}

.buy-ticket p {
	position: relative;
	margin-bottom: 30px;
}

.buy-ticket h2 {
	position: relative;
	display: block;
	font-size: 32px;
	line-height: 1.2em;
	color: #3f4161;
	font-weight: 600;
	margin-bottom: 15px;
}

.buy-ticket h2 span {
	color: #8c7c20;
}

.buy-ticket h3 {
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #3f4161;
	font-weight: 600;
	margin-bottom: 15px;
}

.buy-ticket ul {
	margin-bottom: 30px;
}

.buy-ticket .ticket-form {
	position: relative;
	padding: 40px 30px;
	background-color: #f7f7f7;
	z-index: 1;
	margin-bottom: 40px;
}

.ticket-form .form-group {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 20px;
}

.ticket-form .form-group:last-child {
	margin-bottom: 0;
}

.ticket-form .form-group input[type="text"],
.ticket-form .form-group input[type="email"],
.ticket-form .form-group input[type="phone"],
.ticket-form .form-group input[type="number"],
.ticket-form .form-group input[type="tel"],
.ticket-form .form-group input[type="url"] .ticket-form .form-group textarea,
.ticket-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	color: #9da6b4;
	font-weight: 400;
	height: 60px;
	padding: 20px 30px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.ticket-form .form-group input:focus,
.ticket-form .form-group select:focus,
.ticket-form .form-group textarea:focus {
	background-color: #ffffff;
	border-color: #faeff5;
	color: #9da6b4;
	box-shadow: 0 30px 30px rgba(14, 16, 48, .08);
}

.ticket-form .form-group textarea {
	height: 150px;
	resize: none;
}

.ticket-form .form-group input[type=submit],
.ticket-form .form-group button {
	cursor: pointer;
	display: block;
	width: 100%;
}

.ticket-form .form-group label {
	position: relative;
	font-size: 14px;
	line-height: 24px;
	color: #888888;
}

.ticket-form .form-group label:hover {
	cursor: pointer;
}

.ticket-form .form-group label span {
	color: #8c7c20;
	text-decoration: underline;
}

.ticket-form .form-group input[type=checkbox] {
	position: relative;
	top: 2px;
}

.buy-ticket .follow-us {
	position: relative;
	margin-bottom: 30px;
}

.buy-ticket .support-box {
	position: relative;
}

.buy-ticket .support-box p {
	margin-bottom: 10px;
}

.buy-ticket .support-box .number {
	position: relative;
	font-size: 20px;
	line-height: 1.2em;
	color: #1c94d2;
	font-weight: 500;
}

.buy-ticket .support-box .number a {
	color: #1c94d2;
}

.buy-ticket .support-box .number span {
	color: #3f4161;
	font-size: 18px;
	margin-right: 5px;
}

.buy-ticket .support-box .number a:hover {
	text-decoration: underline;
}

.buy-ticket .icon-circle-blue {
	left: -150px;
	bottom: -150px;
	opacity: .70;
	-webkit-animation: fa-spin 30s infinite;
	-moz-animation: fa-spin 30s infinite;
	-ms-animation: fa-spin 30s infinite;
	-o-animation: fa-spin 30s infinite;
	animation: fa-spin 30s infinite;
}

.buy-ticket .icon-circle-1 {
	right: -250px;
	top: -250px;
	opacity: .05;
}

/*=========================================================
			Social icon Colored
=========================================================*/

.social-icon-colored .fa-adn {
	color: #504e54;
}

.social-icon-colored .fa-apple {
	color: #aeb5c5;
}

.social-icon-colored .fa-android {
	color: #A5C63B;
}

.social-icon-colored .fa-bitbucket,
.social-icon-colored .fa-bitbucket-square {
	color: #003366;
}

.social-icon-colored .fa-bitcoin,
.social-icon-colored .fa-btc {
	color: #F7931A;
}

.social-icon-colored .fa-css3 {
	color: #1572B7;
}

.social-icon-colored .fa-dribbble {
	color: #F46899;
}

.social-icon-colored .fa-dropbox {
	color: #018BD3;
}

.social-icon-colored .fa-facebook,
.social-icon-colored .fa-facebook-f,
.social-icon-colored .fa-facebook-square {
	color: #3C599F;
}

.social-icon-colored .fa-flickr {
	color: #FF0084;
}

.social-icon-colored .fa-foursquare {
	color: #0086BE;
}

.social-icon-colored .fa-github,
.social-icon-colored .fa-github-alt,
.social-icon-colored .fa-github-square {
	color: #070709;
}

.social-icon-colored .fa-google-plus,
.social-icon-colored .fa-google-plus-g,
.social-icon-colored .fa-google-plus-square {
	color: #CF3D2E;
}

.social-icon-colored .fa-html5 {
	color: #E54D26;
}

.social-icon-colored .fa-instagram {
	color: #A1755C;
}

.social-icon-colored .fa-linkedin,
.social-icon-colored .fa-linkedin-in,
.social-icon-colored .fa-linkedin-square {
	color: #0085AE;
}

.social-icon-colored .fa-linux {
	color: #FBC002;
	color: #333;
}

.social-icon-colored .fa-maxcdn {
	color: #F6AE1C;
}

.social-icon-colored .fa-pagelines {
	color: #241E20;
	color: #3984EA;
}

.social-icon-colored .fa-pinterest,
.social-icon-colored .fa-pinterest-square {
	color: #CC2127;
}

.social-icon-colored .fa-renren {
	color: #025DAC;
}

.social-icon-colored .fa-skype {
	color: #01AEF2;
}

.social-icon-colored .fa-stack-exchange {
	color: #245590;
}

.social-icon-colored .fa-stack-overflow {
	color: #FF7300;
}

.social-icon-colored .fa-trello {
	color: #265A7F;
}

.social-icon-colored .fa-tumblr,
.social-icon-colored .fa-tumblr-square {
	color: #314E6C;
}

.social-icon-colored .fa-twitter,
.social-icon-colored .fa-twitter-square {
	color: #32CCFE;
}

.social-icon-colored .fa-vimeo-square {
	color: #229ACC;
}

.social-icon-colored .fa-vk {
	color: #375474;
}

.social-icon-colored .fa-weibo {
	color: #D72B2B;
}

.social-icon-colored .fa-windows {
	color: #12B6F3;
}

.social-icon-colored .fa-xing,
.social-icon-colored .fa-xing-square {
	color: #00555C;
}

.social-icon-colored .fa-youtube,
.social-icon-colored .fa-youtube-play,
.social-icon-colored .fa-youtube-square {
	color: #C52F30;
}

/* Social icon Colored */
.social-icon-colored li a:hover .fa-adn {
	background-color: #504e54;
}

.social-icon-colored li a:hover .fa-apple {
	background-color: #aeb5c5;
}

.social-icon-colored li a:hover .fa-android {
	background-color: #A5C63B;
}

.social-icon-colored li a:hover .fa-bitbucket,
.social-icon-colored li a:hover .fa-bitbucket-square {
	background-color: #003366;
}

.social-icon-colored li a:hover .fa-bitcoin,
.social-icon-colored li a:hover .fa-btc {
	background-color: #F7931A;
}

.social-icon-colored li a:hover .fa-css3 {
	background-color: #1572B7;
}

.social-icon-colored li a:hover .fa-dribbble {
	background-color: #F46899;
}

.social-icon-colored li a:hover .fa-dropbox {
	background-color: #018BD3;
}

.social-icon-colored li a:hover .fa-facebook,
.social-icon-colored li a:hover .fa-facebook-f,
.social-icon-colored li a:hover .fa-facebook-square {
	background-color: #3C599F;
}

.social-icon-colored li a:hover .fa-flickr {
	background-color: #FF0084;
}

.social-icon-colored li a:hover .fa-foursquare {
	background-color: #0086BE;
}

.social-icon-colored li a:hover .fa-github,
.social-icon-colored li a:hover .fa-github-alt,
.social-icon-colored li a:hover .fa-github-square {
	background-color: #070709;
}

.social-icon-colored li a:hover .fa-google-plus,
.social-icon-colored li a:hover .fa-google-plus-g,
.social-icon-colored li a:hover .fa-google-plus-square {
	background-color: #CF3D2E;
}

.social-icon-colored li a:hover .fa-html5 {
	background-color: #E54D26;
}

.social-icon-colored li a:hover .fa-instagram {
	background-color: #A1755C;
}

.social-icon-colored li a:hover .fa-linkedin,
.social-icon-colored li a:hover .fa-linkedin-in,
.social-icon-colored li a:hover .fa-linkedin-square {
	background-color: #0085AE;
}

.social-icon-colored li a:hover .fa-linux {
	background-color: #FBC002;
	color: #333;
}

.social-icon-colored li a:hover .fa-maxcdn {
	background-color: #F6AE1C;
}

.social-icon-colored li a:hover .fa-pagelines {
	background-color: #241E20;
	color: #3984EA;
}

.social-icon-colored li a:hover .fa-pinterest,
.social-icon-colored li a:hover .fa-pinterest-square {
	background-color: #CC2127;
}

.social-icon-colored li a:hover .fa-renren {
	background-color: #025DAC;
}

.social-icon-colored li a:hover .fa-skype {
	background-color: #01AEF2;
}

.social-icon-colored li a:hover .fa-stack-exchange {
	background-color: #245590;
}

.social-icon-colored li a:hover .fa-stack-overflow {
	background-color: #FF7300;
}

.social-icon-colored li a:hover .fa-trello {
	background-color: #265A7F;
}

.social-icon-colored li a:hover .fa-tumblr,
.social-icon-colored li a:hover .fa-tumblr-square {
	background-color: #314E6C;
}

.social-icon-colored li a:hover .fa-twitter,
.social-icon-colored li a:hover .fa-twitter-square {
	background-color: #32CCFE;
}

.social-icon-colored li a:hover .fa-vimeo-square {
	background-color: #229ACC;
}

.social-icon-colored li a:hover .fa-vk {
	background-color: #375474;
}

.social-icon-colored li a:hover .fa-weibo {
	background-color: #D72B2B;
}

.social-icon-colored li a:hover .fa-windows {
	background-color: #12B6F3;
}

.social-icon-colored li a:hover .fa-xing,
.social-icon-colored li a:hover .fa-xing-square {
	background-color: #00555C;
}

.social-icon-colored li a:hover .fa-youtube,
.social-icon-colored li a:hover .fa-youtube-play,
.social-icon-colored li a:hover .fa-youtube-square {
	background-color: #C52F30;
}

.social-icon-colored li a:hover .fab {
	color: #ffffff;
}

/***

==================================================================
		Right To Left Style
==================================================================

***/

.rtl {
	direction: rtl;
	text-align: right;
}

.rtl .social-icon-three li,
.rtl .social-icon-one,
.rtl .banner-conference .time-countdown,
.rtl .styled-pagination,
.rtl .owl-carousel {
	direction: ltr !important;
}

.rtl .banner-conference .layer-outer,
.rtl .anim-icons {
	transform: scaleX(-1);
}

.rtl .main-header .search-box-outer,
.rtl .schedule-tabs.style-three .tab-buttons li,
.rtl .main-header .nav-outer .main-menu,
.rtl .main-header .logo-box {
	float: right;
}

.rtl .blog-categories li a span,
.rtl .fluid-section-one .content-column,
.rtl .main-header .nav-outer {
	float: left;
}

.rtl .main-menu .navigation>li>ul>li.dropdown>a:before {
	right: auto;
	left: 25px;
}

.rtl .list-style-one li:before,
.rtl .list-style-two li:before,
.rtl .fluid-section-one .image-column,
.rtl .schedule-block.style-two .speaker-info .thumb,
.rtl .pricing-block-two .features li:before,
.rtl .event-detail .speaker-info .thumb,
.rtl .event-info-tabs .tabs-content .info-list li .icon,
.rtl .comments-area .comment-box .author-thumb,
.rtl .blog-single .lower-content blockquote cite:before,
.rtl .about-section-two .about-block .icon,
.rtl .contact-page-section .contact-info li .icon,
.rtl .subscribe-section .content-box .sec-title .icon,
.rtl .sidebar .popular-posts .post .post-thumb,
.rtl .main-footer .footer-column .widget-title:before,
.rtl .pricing-block .features li:before,
.rtl .main-menu .navigation>li>ul {
	left: auto;
	right: 0;
}

.rtl .main-menu .navigation>li>ul>li>ul {
	right: 100%;
	left: auto;
}

.rtl .sidebar .search-box .form-group button,
.rtl .subscribe-form .form-group input[type="submit"],
.rtl .subscribe-form .form-group button,
.rtl .banner-conference .images-outer,
.rtl .banner-conference .layer-outer {
	right: auto;
	left: 0;
}

.rtl .main-header .outer-box {
	float: left;
	margin-right: 50px;
	margin-left: 0;
}

.rtl .main-header .outer-box .btn-box {
	float: right;
	margin-left: 0;
	margin-right: 20px;
}

.rtl .main-header .search-box-outer .dropdown-menu {
	right: auto !important;
	left: 0 !important;
}

.rtl .main-menu .navigation>li {
	float: right;
	margin-right: 45px;
	margin-left: 0;
}

.rtl .banner-carousel .content-box .info-list li .icon {
	float: right;
	margin-left: 10px;
}

.rtl .coming-soon-section .time-countdown .counter-column:before {
	right: auto;
	left: -50px;
}

.rtl .list-style-one li {
	padding-left: 0;
	padding-right: 35px;
}

.rtl .about-section .image-column .image-box {
	padding-left: 0;
	padding-right: 80px;
}

.rtl .about-section .content-column .inner-column {
	padding-right: 0;
	padding-left: 80px;
}

.rtl .feature-block-two .inner-box {
	padding: 40px 30px;
	padding-right: 100px;
}

.rtl .feature-block-two .icon-box {
	left: auto;
	right: 30px;
}

.rtl .pricing-block .features li {
	padding: 5px 0;
	padding-right: 30px;
}

.rtl .register-form .form-group input[type="text"],
.rtl .register-form .form-group input[type="email"],
.rtl .register-form .form-group input[type="url"],
.rtl .register-form .form-group input[type="number"],
.rtl .register-form .form-group input[type="date"],
.rtl .register-form .form-group input[type="email"],
.rtl .register-form .form-group textarea,
.register-form .form-group select {
	padding: 10px 20px;
	padding-right: 50px;
}

.rtl .register-form .form-group .icon {
	left: auto;
	right: 20px;
}

.rtl .user-links li a {
	border-left: 0;
	border-right: 1px solid #f70068;
}

.rtl .main-footer .contact-list li {
	padding-left: 0;
	padding-right: 40px;
}

.rtl .main-footer .contact-list li .icon {
	left: auto;
	right: 0px;
}

.rtl .speaker-block-two .info-box {
	border-radius: 30px 0 0 30px;
}

.rtl .schedule-section.style-two .schedule-block .date,
.rtl .schedule-section.style-two .schedule-block .inner-box {
	text-align: right;
}

.rtl .schedule-block.style-two .speaker-info {
	padding-left: 0;
	padding-right: 80px;
}

.rtl .pricing-block-two .title .icon {
	margin-right: 0;
	margin-left: 5px;
}

.rtl .pricing-block-two .price-box {
	margin-left: 0;
	margin-right: -30px;
}

.rtl .pricing-block-two .price {
	border-radius: 50px 0 0 50px;
}

.rtl .pricing-block-two .features li {
	padding-right: 30px;
	padding-left: 0;
}

.rtl .banner-meetup .address .icon,
.rtl .event-info-tabs .tabs-content h4 .icon {
	margin-right: 0;
	margin-left: 10px;
}

.rtl .event-info-tabs .tabs-content .info-list li {
	padding-left: 0;
	padding-right: 30px;
}

.rtl .subscribe-section .content-box .sec-title {
	text-align: right;
}

.rtl .subscribe-section .content-box .sec-title {
	padding-right: 90px;
	padding-left: 0;
}

.rtl .about-section-two .image-column .image-box {
	padding-right: 0;
	padding-left: 50px;
}

.rtl .about-section-two .about-block h4 {
	padding-left: 0;
	padding-right: 35px;
}

.rtl .feature-block-three .inner-box {
	padding-right: 170px;
	padding-left: 100px;
}

.rtl .feature-block-three .icon-box {
	left: auto;
	right: 50px;
}

.rtl .feature-block-three .link-box {
	right: auto;
	left: 30px;
}

.rtl .speaker-block-three .info-box {
	padding: 10px 30px;
	padding-left: 10px;
}

.rtl .speaker-block-three .info-box:before {
	right: 0;
	left: -30px;
	border-radius: 14px 0 0 14px;
}

.rtl .schedule-section.style-three .schedule-block .date,
.rtl .schedule-section.style-three .schedule-block .inner-box {
	text-align: right;
}

.rtl .accordion-box .block .acc-btn {
	padding: 15px 25px 15px 25px;
	padding-left: 50px;
}

.rtl .accordion-box .block .icon-outer {
	right: auto;
	left: 25px;
}

.rtl .app-section .link-box a {
	margin-right: 0;
	margin-left: 25px;
}

.rtl .app-section .image-column .inner-column {
	text-align: left;
}

.rtl .event-info-section .info-column .inner-column {
	left: 0;
	padding-right: 30px;
}

.rtl .event-info-tabs .tab-btn {
	float: right;
	margin-left: 5px;
	margin-right: 0;
}

.rtl .subscribe-form .form-group {
	padding-right: 0;
	padding-left: 70px;
}

.rtl .about-section .icon-dots {
	left: auto;
	right: 50%;
}

.rtl .coming-soon .emailed-form .form-group input[type="submit"],
.rtl .coming-soon .emailed-form button {
	right: auto;
	left: 35px;
	transform: scaleX(-1);
}

.rtl .event-detail .speaker-info {
	padding-left: 0;
	padding-right: 75px;
}

.rtl .list-style-two li {
	padding-left: 0;
	padding-right: 25px;
}

.rtl .buy-ticket .support-box .number span {
	margin-left: 5px;
	margin-right: 0;
}

.rtl .buy-ticket .form-column .inner-column {
	padding-right: 0;
	padding-left: 30px;
}

.rtl .news-block .post-info li {
	margin-left: 25px;
	margin-right: 0;
}

.rtl .news-block .post-info li span {
	margin-right: 0;
	margin-left: 5px;
}

.rtl .sidebar-page-container .sidebar.padding-left {
	padding-left: 0;
	padding-right: 40px;
}

.rtl .sidebar .search-box .form-group input[type="text"],
.rtl .sidebar .search-box .form-group input[type="search"] {
	padding: 20px 30px 20px 50px;
}

.rtl .sidebar .popular-posts .post .post-inner {
	padding-left: 0;
	padding-right: 110px;
}

.rtl .sidebar .popular-tags a {
	float: right;
	margin: 0 0px 10px 20px;
}

.rtl .blog-single .lower-content blockquote {
	padding: 35px 40px;
	padding-right: 95px;
}

.rtl .blog-single .lower-content blockquote .icon {
	left: auto;
	right: 40px;
}

.rtl .blog-single .lower-content blockquote cite {
	padding-left: 0;
	padding-right: 30px;
}

.rtl .comments-area .comment {
	padding-left: 0;
	padding-right: 70px;
}

.rtl .comments-area .comment-box .name {
	margin-right: 0;
	margin-left: 10px;
}

.rtl .contact-page-section .contact-info li {
	padding-left: 0;
	padding-right: 55px;
}


.rtl .schedule-section.style-three .schedule-block .speaker-info,
.rtl .schedule-section.style-two .schedule-block .speaker-info {
	padding-left: 0;
	padding-right: 80px;
}

.rtl .schedule-section.style-three .schedule-block .speaker-info .thumb,
.rtl .schedule-section.style-two .schedule-block .speaker-info .thumb {
	left: auto;
	right: 0;
}

.rtl .banner-conference-two .time-countdown .counter-column {
	margin-right: 0px;
	margin-left: 30px;
}

.rtl .banner-conference-two .time-countdown .counter-column:before {
	right: auto;
	left: -20px;
}

.rtl .banner-conference-two .images-outer {
	left: 0px;
	right: auto;
}

.rtl .header-top .top-left {
	order: 2;
}

.rtl .header-top .top-right {
	order: 1;
}

.rtl .header-top-two .top-left .links li a {
	padding-left: 0px;
	padding-right: 24px;
}

.rtl .header-top-two .top-left .links li a .icon {
	right: 0px;
	left: auto;
}

.rtl .header-top-two .top-right .cart-box {
	margin-left: 30px;
}

/*** 

====================================================================
	Box Layout
====================================================================

***/

.box-layout .page-wrapper {
	max-width: 1280px;
	margin: 0 auto;
	background-color: #ffffff;
	overflow: hidden;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.10);
}

.box-layout .main-header {
	max-width: 1280px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.box-layout {
	background-repeat: repeat;
	background-position: center;
	background-attachment: fixed;
	background-image: url(../images/icons/boxed-bg.png);
}

.box-layout .coming-soon {
	position: relative;
}

/* Header Top */

.header-top {
	position: relative;
	background-color: #8c7c20;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.header-top .top-left {
	position: relative;
	padding: 15px 0px;
}

.header-top .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
}

.header-top .top-right {
	position: relative;
}

.header-span.style-two {
	height: 145px;
}

.header-top .social-links {
	position: relative;
	padding: 16px 0px;
	float: left;
}

.header-top .social-links li {
	position: relative;
	margin-left: 18px;
	display: inline-block;
}

.header-top .social-links li a {
	position: relative;
	color: #ffffff;
	font-size: 14px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.header-top .social-links li a:hover {
	color: #4c35a9;
}

.header-top .register-btn {
	position: relative;
	color: #ffffff;
	float: left;
	font-size: 16px;
	padding: 16.5px 30px;
	margin-left: 25px;
	background-color: #1c94d2;
}

.main-header.header-style-two.alternate-two {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
}

.main-header.header-style-two.alternate-two .outer-box .search-box-btn,
.main-header.header-style-two.alternate-two .main-menu .navigation>li>a {
	color: #ffffff;
}

.main-header.header-style-two.alternate-two.fixed-header .outer-box .search-box-btn,
.main-header.header-style-two.alternate-two.fixed-header .main-menu .navigation>li>a {
	color: #000000;
}

.main-header.header-style-two.alternate.fixed-header .header-top,
.main-header.header-style-four.fixed-header .header-top-two,
.main-header.header-style-two.alternate-two.fixed-header .header-top-two {
	display: none;
}

.main-header.header-style-two.alternate-two .main-box {
	position: fixed;
	width: 100%;
}

/* Banner Section Two */

.banner-section-two {
	position: relative;
	padding: 150px 0px 350px;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.banner-section-two .outer-container {
	position: relative;
}

.banner-section-two .outer-container .content {
	position: relative;
	width: 100%;
	max-width: 800px;
	text-align: center;
	display: inline-block;
	background-color: #8c7c20;
}

.banner-section-two .outer-container .content .upper-content {
	position: relative;
	padding: 10px 30px;
	display: inline-block;
	background-color: #8c7c20;
}

.banner-section-two .outer-container .title {
	position: relative;
	color: #ffffff;
	font-size: 20px;
}

.banner-section-two .outer-container .content h1 {
	position: relative;
	font-weight: 800;
	color: #ffffff;
	font-size: 80px;
	line-height: 1em;
	margin-top: 12px;
}

.banner-section-two .outer-container .content h1 span {
	position: relative;
	font-weight: 300;
}

.banner-section-two .outer-container .content h2 {
	position: relative;
	font-weight: 400;
	color: #ffffff;
	font-size: 35px;
	line-height: 1.1em;
	margin-top: 18px;
	text-transform: uppercase;
}

.banner-section-two .outer-container .content h2 span {
	font-weight: 300;
	display: block;
	font-size: 28px;
}

.banner-section-two .outer-container .content .lower-content {
	position: relative;
	padding: 10px 50px;
	background-color: #363636;
}

.banner-section-two .outer-container .content .lower-content .lower-title {
	position: relative;
	color: #ffffff;
	font-weight: 300;
	font-size: 18px;
	font-style: italic;
}

.banner-section-two .outer-container .content .lower-content .date {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	font-style: italic;
	margin-top: 8px;
}

/*** 

====================================================================
	Coming Soon Section Two
====================================================================

***/

.coming-soon-section-two {
	position: relative;
	z-index: 2;
}

.coming-soon-section-two .outer-box:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 42px;
	background-color: #363941;
}

.coming-soon-section-two .outer-box {
	position: relative;
	display: block;
	padding: 0px 80px 40px;
	background-color: #8c7c20;
	text-align: center;
	margin-top: -142px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.30);
}

.coming-soon-section-two .timer-column {
	position: relative;
}

.coming-soon-section-two .time-counter {
	position: relative;

}

.coming-soon-section-two .time-countdown {
	position: relative;
}

.coming-soon-section-two .time-countdown .counter-column {
	position: relative;
	display: inline-block;
	margin: 0px 40px;
	width: 143px;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	top: 30px;
	font-weight: 300;
	font-style: italic;
	text-transform: uppercase;
}

.coming-soon-section-two .time-countdown .counter-column:before {
	position: absolute;
	content: ':';
	top: 5px;
	right: -50px;
	font-size: 80px;
	line-height: 1em;
	color: #ffffff;
	font-style: normal;
}

.coming-soon-section-two .time-countdown .counter-column:last-child:before {
	display: none;
}

.coming-soon-section-two .time-countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 90px;
	line-height: 1em;
	color: #ffffff;
	font-weight: 700;
	padding-bottom: 30px;
	font-style: normal;
}

.header-top-two {
	position: relative;
	border-bottom: 1px solid rgba(245, 245, 245, 0.50);
}

.header-top-two .top-left {
	position: relative;
	float: left;
}

.header-top-two .top-left .links {
	position: relative;
}

.header-top-two .top-left .links li {
	position: relative;
	margin-right: 18px;
	padding: 13px 0px;
	padding-right: 22px;
	display: inline-block;
	border-right: 1px solid rgba(245, 245, 245, 0.50);
}

.header-top-two .top-left .links li:last-child {
	border-right: none;
}

.header-top-two .top-left .links li a {
	position: relative;
	font-size: 15px;
	font-weight: 400;
	color: #ffffff;
	padding-left: 24px;
}

.header-top-two .top-left .links li {
	color: #ffffff;
}

.header-top-two .top-left .links li a .icon {
	position: absolute;
	left: 0px;
	color: #8c7c20;
}

.header-top-two .top-left .links li .icon {
	color: #8c7c20;
}

.header-top-two .top-right {
	position: relative;
	float: right;
}

.header-top-two .top-right .social-icons {
	position: relative;
	float: left;
}

.header-top-two .top-right .social-icons li {
	position: relative;
	margin-left: 15px;
	padding: 13px 0px;
	display: inline-block;
}

.header-top-two .top-right .social-icons li a {
	position: relative;
	color: #ffffff;
	font-size: 16px;
}

/*** 

====================================================================
	Banner Conference Two
====================================================================

***/

.banner-conference-two {
	position: relative;
	padding: 320px 0px 200px;
	overflow: hidden;
}

.banner-conference-two .icons {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.banner-conference-two .icons .icon-one {
	position: absolute;
	left: 10% !important;
	top: 17% !important;
	width: 12px;
	height: 12px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-two {
	position: absolute;
	left: 35% !important;
	top: 25% !important;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-three {
	position: absolute;
	left: 6% !important;
	top: 58% !important;
	width: 34px;
	height: 10px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-four {
	position: absolute;
	left: 56% !important;
	top: 60% !important;
	width: 34px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-five {
	position: absolute;
	left: 79% !important;
	top: 25% !important;
	width: 35px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-six {
	position: absolute;
	left: 90% !important;
	top: 88% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-seven {
	position: absolute;
	left: 80% !important;
	top: 60% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-eight {
	position: absolute;
	left: 52% !important;
	top: 40% !important;
	width: 12px;
	height: 12px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-nine {
	position: absolute;
	left: 52% !important;
	top: 12% !important;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-ten {
	position: absolute;
	left: 34% !important;
	top: 68% !important;
	width: 34px;
	height: 10px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-eleven {
	position: absolute;
	left: 89% !important;
	top: 80% !important;
	width: 34px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-twelve {
	position: absolute;
	left: 69% !important;
	top: 70% !important;
	width: 35px;
	height: 16px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-thirteen {
	position: absolute;
	left: 200% !important;
	top: 88% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two .icons .icon-fourteen {
	position: absolute;
	left: 95% !important;
	top: 95% !important;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
}

.banner-conference-two:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.85);
}

.banner-conference-two .icon-dots {
	left: 40%;
	bottom: 180px;
}

.banner-conference-two .layer-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference-two .images-outer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50%;
}

.banner-conference-two .images-outer .speaker-img {
	position: absolute;
	right: 150px;
	top: 52%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.banner-conference-two .content-box {
	position: relative;
	z-index: 99;
}

.banner-conference-two .content-box .title {
	display: block;
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 20px;
}

.banner-conference-two .content-box h2 {
	display: block;
	font-size: 50px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 50px;
}

.banner-conference-two .time-counter {
	position: relative;
	margin-bottom: 30px;
}

.banner-conference-two .time-countdown {
	position: relative;
}

.banner-conference-two .time-countdown .counter-column {
	position: relative;
	display: inline-block;
	margin-right: 30px;
	width: 90px;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	font-weight: 500;
}

.banner-conference-two .time-countdown .counter-column:last-child {
	margin-right: 0;
}

.banner-conference-two .time-countdown .counter-column:before {
	position: absolute;
	content: ':';
	top: 10px;
	right: -20px;
	font-size: 30px;
	line-height: 1em;
	color: #ffffff;
}

.banner-conference-two .time-countdown .counter-column:after {
	position: absolute;
	left: 0;
	top: -20px;
	height: 100px;
	width: 90px;
	z-index: -1;
	border-radius: 5px 30px 5px 30px;
	background-color: #8c7c20;
	content: "";
}

.banner-conference-two .time-countdown .counter-column:nth-child(2):after {
	background-color: #1c94d2;
}

.banner-conference-two .time-countdown .counter-column:nth-child(3):after {
	background-color: #8c7c20;
}

.banner-conference-two .time-countdown .counter-column:nth-child(4):after {
	background-color: #4c35a9;
}

.banner-conference-two .time-countdown .counter-column:last-child:before {
	display: none;
}

.banner-conference-two .time-countdown .counter-column .count {
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1em;
	color: #ffffff;
	font-weight: 700;
}

.banner-conference-two .content-box .btn-box {
	position: relative;
	margin-top: 60px;
}

/*** 

====================================================================
	Search Popup
====================================================================

***/

.search-popup {
	position: fixed;
	left: 0px;
	bottom: -100%;
	width: 100%;
	height: 100%;
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	overflow: auto;
	background: rgba(0, 0, 0, 0.80);
	transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
}

.search-popup .overlay-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
}

.search-popup.popup-visible {
	bottom: 0;
	visibility: visible;
	opacity: 1;
}

.search-popup .close-search {
	position: absolute;
	right: 25px;
	top: 25px;
	font-size: 22px;
	color: #ffffff;
	cursor: pointer;
	z-index: 5;
}

.search-popup .close-search:hover {
	opacity: 0.70;
}

.search-popup .search-form {
	position: relative;
	padding: 0px 15px 0px;
	max-width: 1024px;
	margin: 0 auto;
	margin-top: 200px;
}

.search-popup .search-form fieldset {
	position: relative;
	border: 7px solid rgba(255, 255, 255, 0.50);
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
	-moz-border-radius: 12px;
}

.search-popup .search-form fieldset input[type="search"] {
	position: relative;
	height: 70px;
	padding: 20px 220px 20px 30px;
	background: #ffffff;
	line-height: 30px;
	font-size: 24px;
	color: #233145;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	-moz-border-radius: 7px;
}

.search-popup .search-form fieldset input[type="submit"] {
	position: absolute;
	display: block;
	right: 0px;
	top: 0px;
	text-align: center;
	width: 220px;
	height: 70px;
	padding: 20px 10px 20px 10px;
	color: #ffffff !important;
	background-color: #8c7c20;
	line-height: 30px;
	font-size: 20px;
	color: #233145;
	cursor: pointer;
	text-transform: uppercase;
	border-radius: 0px 7px 7px 0px;
	-moz-border-radius: 0px 7px 7px 0px;
	-ms-border-radius: 0px 7px 7px 0px;
	-o-border-radius: 0px 7px 7px 0px;
	-webkit-border-radius: 0px 7px 7px 0px;
}

.search-popup .search-form fieldset input[type="submit"]:hover {
	background: #8c7c20;
}

.search-popup h3 {
	font-size: 24px;
	color: #ffffff;
	margin-bottom: 20px;
	text-align: center;
}

.search-popup .recent-searches {
	font-size: 16px;
	color: #ffffff;
	text-align: center;
}

.search-popup .recent-searches li {
	display: inline-block;
	margin: 0px 10px 10px 0px;
}

.search-popup .recent-searches li a {
	display: block;
	line-height: 24px;
	border: 1px solid #ffffff;
	padding: 7px 15px;
	color: #ffffff;
	border-radius: 3px;
}

.search-popup .recent-searches li a:hover {
	color: #8c7c20;
	border-color: #8c7c20;
}

/*** 

====================================================================
	Register Section
====================================================================

 ***/

.register-section {
	position: relative;
	padding: 0px 0px 20px;
}

.register-section .form-box {
	position: relative;
	max-width: 550px;
	padding: 40px 30px;
	margin: 0 auto;
	width: 100%;
	/* background-color: #f9f9f9; */
	/* border: 3px solid #f70068; */
}

.register-section .form-box h1 {
	position: relative;
	color: #222222;
	font-size: 30px;
	font-weight: 700;
	line-height: 1.3em;
	text-align: center;
	margin-bottom: 30px;
}

/* Styled Form */

.styled-form {
	position: relative;
}

.styled-form .submit-text {
	font-size: 15px;
	color: #9e9e9e;
	font-weight: 400;
	margin-top: 10px;
}

.styled-form .form-group {
	position: relative;
	margin-bottom: 15px;
	font-size: 14px;
	color: #222222;
}

.styled-form .form-group.social-icon-one {
	padding-top: 8px;
}

.checkout-section .styled-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.styled-form .form-group .check-box {
	line-height: 24px;
	font-size: 15px;
	font-weight: normal;
	padding-top: 5px;
	color: #272727;
}

.styled-form .form-group .check-box label {
	position: relative;
	top: -1px;
	font-weight: normal;
	padding: 0px;
	font-size: 15px;
	cursor: pointer;
}

.styled-form .pull-left label,
.styled-form .pull-right label {
	cursor: pointer;
}

.styled-form .form-group .adon-icon {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 10px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 15px;
	color: #999999;
	z-index: 2;
}

.styled-form .form-group .adon-icon .fa-eye-slash {
	color: #8c7c20;
}

.styled-form .form-group .field-label {
	display: block;
	color: #272727;
	text-transform: capitalize;
	font-size: 16px;
	margin-bottom: 7px;
	font-weight: 400;
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
	position: relative;
	display: block;
	width: 100%;
	background: #ffffff;
	line-height: 30px;
	padding: 8px 20px;
	height: 50px;
	border: 1px solid #f4f4f4;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.event-info-section .map-outer iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 435px;
	margin-bottom: -10px;
}

.map-section .map-outer iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 540px;
	margin-bottom: -10px;
}