@font-face {
	font-family: 'ElegantIcons';
	src: url('../fonts/ElegantIcons.eot');
	src: url('../fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ElegantIcons.woff') format('woff'),
		url('../fonts/ElegantIcons.ttf') format('truetype'),
		url('../fonts/ElegantIcons.svg#ElegantIcons') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'ElegantIcons';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="your-class-prefix"] {
*/
.arrow_up,
.arrow_down,
.arrow_left,
.arrow_right,
.arrow_left-up,
.arrow_right-up,
.arrow_right-down,
.arrow_left-down,
.arrow-up-down,
.arrow_up-down_alt,
.arrow_left-right_alt,
.arrow_left-right,
.arrow_expand_alt2,
.arrow_expand_alt,
.arrow_condense,
.arrow_expand,
.arrow_move,
.arrow_carrot-up,
.arrow_carrot-down,
.arrow_carrot-left,
.arrow_carrot-right,
.arrow_carrot-2up,
.arrow_carrot-2down,
.arrow_carrot-2left,
.arrow_carrot-2right,
.arrow_carrot-up_alt2,
.arrow_carrot-down_alt2,
.arrow_carrot-left_alt2,
.arrow_carrot-right_alt2,
.arrow_carrot-2up_alt2,
.arrow_carrot-2down_alt2,
.arrow_carrot-2left_alt2,
.arrow_carrot-2right_alt2,
.arrow_triangle-up,
.arrow_triangle-down,
.arrow_triangle-left,
.arrow_triangle-right,
.arrow_triangle-up_alt2,
.arrow_triangle-down_alt2,
.arrow_triangle-left_alt2,
.arrow_triangle-right_alt2,
.arrow_back,
.icon_minus-06,
.icon_plus,
.icon_close,
.icon_check,
.icon_minus_alt2,
.icon_plus_alt2,
.icon_close_alt2,
.icon_check_alt2,
.icon_zoom-out_alt,
.icon_zoom-in_alt,
.icon_search,
.icon_box-empty,
.icon_box-selected,
.icon_minus-box,
.icon_plus-box,
.icon_box-checked,
.icon_circle-empty,
.icon_circle-slelected,
.icon_stop_alt2,
.icon_stop,
.icon_pause_alt2,
.icon_pause,
.icon_menu,
.icon_menu-square_alt2,
.icon_menu-circle_alt2,
.icon_ul,
.icon_ol,
.icon_adjust-horiz,
.icon_adjust-vert,
.icon_document_alt,
.icon_documents_alt,
.icon_pencil,
.icon_pencil-edit_alt,
.icon_pencil-edit,
.icon_folder-alt,
.icon_folder-open_alt,
.icon_folder-add_alt,
.icon_info_alt,
.icon_error-oct_alt,
.icon_error-circle_alt,
.icon_error-triangle_alt,
.icon_question_alt2,
.icon_question,
.icon_comment_alt,
.icon_chat_alt,
.icon_vol-mute_alt,
.icon_volume-low_alt,
.icon_volume-high_alt,
.icon_quotations,
.icon_quotations_alt2,
.icon_clock_alt,
.icon_lock_alt,
.icon_lock-open_alt,
.icon_key_alt,
.icon_cloud_alt,
.icon_cloud-upload_alt,
.icon_cloud-download_alt,
.icon_image,
.icon_images,
.icon_lightbulb_alt,
.icon_gift_alt,
.icon_house_alt,
.icon_genius,
.icon_mobile,
.icon_tablet,
.icon_laptop,
.icon_desktop,
.icon_camera_alt,
.icon_mail_alt,
.icon_cone_alt,
.icon_ribbon_alt,
.icon_bag_alt,
.icon_creditcard,
.icon_cart_alt,
.icon_paperclip,
.icon_tag_alt,
.icon_tags_alt,
.icon_trash_alt,
.icon_cursor_alt,
.icon_mic_alt,
.icon_compass_alt,
.icon_pin_alt,
.icon_pushpin_alt,
.icon_map_alt,
.icon_drawer_alt,
.icon_toolbox_alt,
.icon_book_alt,
.icon_calendar,
.icon_film,
.icon_table,
.icon_contacts_alt,
.icon_headphones,
.icon_lifesaver,
.icon_piechart,
.icon_refresh,
.icon_link_alt,
.icon_link,
.icon_loading,
.icon_blocked,
.icon_archive_alt,
.icon_heart_alt,
.icon_star_alt,
.icon_star-half_alt,
.icon_star,
.icon_star-half,
.icon_tools,
.icon_tool,
.icon_cog,
.icon_cogs,
.arrow_up_alt,
.arrow_down_alt,
.arrow_left_alt,
.arrow_right_alt,
.arrow_left-up_alt,
.arrow_right-up_alt,
.arrow_right-down_alt,
.arrow_left-down_alt,
.arrow_condense_alt,
.arrow_expand_alt3,
.arrow_carrot_up_alt,
.arrow_carrot-down_alt,
.arrow_carrot-left_alt,
.arrow_carrot-right_alt,
.arrow_carrot-2up_alt,
.arrow_carrot-2dwnn_alt,
.arrow_carrot-2left_alt,
.arrow_carrot-2right_alt,
.arrow_triangle-up_alt,
.arrow_triangle-down_alt,
.arrow_triangle-left_alt,
.arrow_triangle-right_alt,
.icon_minus_alt,
.icon_plus_alt,
.icon_close_alt,
.icon_check_alt,
.icon_zoom-out,
.icon_zoom-in,
.icon_stop_alt,
.icon_menu-square_alt,
.icon_menu-circle_alt,
.icon_document,
.icon_documents,
.icon_pencil_alt,
.icon_folder,
.icon_folder-open,
.icon_folder-add,
.icon_folder_upload,
.icon_folder_download,
.icon_info,
.icon_error-circle,
.icon_error-oct,
.icon_error-triangle,
.icon_question_alt,
.icon_comment,
.icon_chat,
.icon_vol-mute,
.icon_volume-low,
.icon_volume-high,
.icon_quotations_alt,
.icon_clock,
.icon_lock,
.icon_lock-open,
.icon_key,
.icon_cloud,
.icon_cloud-upload,
.icon_cloud-download,
.icon_lightbulb,
.icon_gift,
.icon_house,
.icon_camera,
.icon_mail,
.icon_cone,
.icon_ribbon,
.icon_bag,
.icon_cart,
.icon_tag,
.icon_tags,
.icon_trash,
.icon_cursor,
.icon_mic,
.icon_compass,
.icon_pin,
.icon_pushpin,
.icon_map,
.icon_drawer,
.icon_toolbox,
.icon_book,
.icon_contacts,
.icon_archive,
.icon_heart,
.icon_profile,
.icon_group,
.icon_grid-2x2,
.icon_grid-3x3,
.icon_music,
.icon_pause_alt,
.icon_phone,
.icon_upload,
.icon_download,
.social_facebook,
.social_twitter,
.social_pinterest,
.social_googleplus,
.social_tumblr,
.social_tumbleupon,
.social_wordpress,
.social_instagram,
.social_dribbble,
.social_vimeo,
.social_linkedin,
.social_rss,
.social_deviantart,
.social_share,
.social_myspace,
.social_skype,
.social_youtube,
.social_picassa,
.social_googledrive,
.social_flickr,
.social_blogger,
.social_spotify,
.social_delicious,
.social_facebook_circle,
.social_twitter_circle,
.social_pinterest_circle,
.social_googleplus_circle,
.social_tumblr_circle,
.social_stumbleupon_circle,
.social_wordpress_circle,
.social_instagram_circle,
.social_dribbble_circle,
.social_vimeo_circle,
.social_linkedin_circle,
.social_rss_circle,
.social_deviantart_circle,
.social_share_circle,
.social_myspace_circle,
.social_skype_circle,
.social_youtube_circle,
.social_picassa_circle,
.social_googledrive_alt2,
.social_flickr_circle,
.social_blogger_circle,
.social_spotify_circle,
.social_delicious_circle,
.social_facebook_square,
.social_twitter_square,
.social_pinterest_square,
.social_googleplus_square,
.social_tumblr_square,
.social_stumbleupon_square,
.social_wordpress_square,
.social_instagram_square,
.social_dribbble_square,
.social_vimeo_square,
.social_linkedin_square,
.social_rss_square,
.social_deviantart_square,
.social_share_square,
.social_myspace_square,
.social_skype_square,
.social_youtube_square,
.social_picassa_square,
.social_googledrive_square,
.social_flickr_square,
.social_blogger_square,
.social_spotify_square,
.social_delicious_square,
.icon_printer,
.icon_calulator,
.icon_building,
.icon_floppy,
.icon_drive,
.icon_search-2,
.icon_id,
.icon_id-2,
.icon_puzzle,
.icon_like,
.icon_dislike,
.icon_mug,
.icon_currency,
.icon_wallet,
.icon_pens,
.icon_easel,
.icon_flowchart,
.icon_datareport,
.icon_briefcase,
.icon_shield,
.icon_percent,
.icon_globe,
.icon_globe-2,
.icon_target,
.icon_hourglass,
.icon_balance,
.icon_rook,
.icon_printer-alt,
.icon_calculator_alt,
.icon_building_alt,
.icon_floppy_alt,
.icon_drive_alt,
.icon_search_alt,
.icon_id_alt,
.icon_id-2_alt,
.icon_puzzle_alt,
.icon_like_alt,
.icon_dislike_alt,
.icon_mug_alt,
.icon_currency_alt,
.icon_wallet_alt,
.icon_pens_alt,
.icon_easel_alt,
.icon_flowchart_alt,
.icon_datareport_alt,
.icon_briefcase_alt,
.icon_shield_alt,
.icon_percent_alt,
.icon_globe_alt,
.icon_clipboard {
	font-family: 'ElegantIcons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
}

.arrow_up:before {
	content: "\21";
}

.arrow_down:before {
	content: "\22";
}

.arrow_left:before {
	content: "\23";
}

.arrow_right:before {
	content: "\24";
}

.arrow_left-up:before {
	content: "\25";
}

.arrow_right-up:before {
	content: "\26";
}

.arrow_right-down:before {
	content: "\27";
}

.arrow_left-down:before {
	content: "\28";
}

.arrow-up-down:before {
	content: "\29";
}

.arrow_up-down_alt:before {
	content: "\2a";
}

.arrow_left-right_alt:before {
	content: "\2b";
}

.arrow_left-right:before {
	content: "\2c";
}

.arrow_expand_alt2:before {
	content: "\2d";
}

.arrow_expand_alt:before {
	content: "\2e";
}

.arrow_condense:before {
	content: "\2f";
}

.arrow_expand:before {
	content: "\30";
}

.arrow_move:before {
	content: "\31";
}

.arrow_carrot-up:before {
	content: "\32";
}

.arrow_carrot-down:before {
	content: "\33";
}

.arrow_carrot-left:before {
	content: "\34";
}

.arrow_carrot-right:before {
	content: "\35";
}

.arrow_carrot-2up:before {
	content: "\36";
}

.arrow_carrot-2down:before {
	content: "\37";
}

.arrow_carrot-2left:before {
	content: "\38";
}

.arrow_carrot-2right:before {
	content: "\39";
}

.arrow_carrot-up_alt2:before {
	content: "\3a";
}

.arrow_carrot-down_alt2:before {
	content: "\3b";
}

.arrow_carrot-left_alt2:before {
	content: "\3c";
}

.arrow_carrot-right_alt2:before {
	content: "\3d";
}

.arrow_carrot-2up_alt2:before {
	content: "\3e";
}

.arrow_carrot-2down_alt2:before {
	content: "\3f";
}

.arrow_carrot-2left_alt2:before {
	content: "\40";
}

.arrow_carrot-2right_alt2:before {
	content: "\41";
}

.arrow_triangle-up:before {
	content: "\42";
}

.arrow_triangle-down:before {
	content: "\43";
}

.arrow_triangle-left:before {
	content: "\44";
}

.arrow_triangle-right:before {
	content: "\45";
}

.arrow_triangle-up_alt2:before {
	content: "\46";
}

.arrow_triangle-down_alt2:before {
	content: "\47";
}

.arrow_triangle-left_alt2:before {
	content: "\48";
}

.arrow_triangle-right_alt2:before {
	content: "\49";
}

.arrow_back:before {
	content: "\4a";
}

.icon_minus-06:before {
	content: "\4b";
}

.icon_plus:before {
	content: "\4c";
}

.icon_close:before {
	content: "\4d";
}

.icon_check:before {
	content: "\4e";
}

.icon_minus_alt2:before {
	content: "\4f";
}

.icon_plus_alt2:before {
	content: "\50";
}

.icon_close_alt2:before {
	content: "\51";
}

.icon_check_alt2:before {
	content: "\52";
}

.icon_zoom-out_alt:before {
	content: "\53";
}

.icon_zoom-in_alt:before {
	content: "\54";
}

.icon_search:before {
	content: "\55";
}

.icon_box-empty:before {
	content: "\56";
}

.icon_box-selected:before {
	content: "\57";
}

.icon_minus-box:before {
	content: "\58";
}

.icon_plus-box:before {
	content: "\59";
}

.icon_box-checked:before {
	content: "\5a";
}

.icon_circle-empty:before {
	content: "\5b";
}

.icon_circle-slelected:before {
	content: "\5c";
}

.icon_stop_alt2:before {
	content: "\5d";
}

.icon_stop:before {
	content: "\5e";
}

.icon_pause_alt2:before {
	content: "\5f";
}

.icon_pause:before {
	content: "\60";
}

.icon_menu:before {
	content: "\61";
}

.icon_menu-square_alt2:before {
	content: "\62";
}

.icon_menu-circle_alt2:before {
	content: "\63";
}

.icon_ul:before {
	content: "\64";
}

.icon_ol:before {
	content: "\65";
}

.icon_adjust-horiz:before {
	content: "\66";
}

.icon_adjust-vert:before {
	content: "\67";
}

.icon_document_alt:before {
	content: "\68";
}

.icon_documents_alt:before {
	content: "\69";
}

.icon_pencil:before {
	content: "\6a";
}

.icon_pencil-edit_alt:before {
	content: "\6b";
}

.icon_pencil-edit:before {
	content: "\6c";
}

.icon_folder-alt:before {
	content: "\6d";
}

.icon_folder-open_alt:before {
	content: "\6e";
}

.icon_folder-add_alt:before {
	content: "\6f";
}

.icon_info_alt:before {
	content: "\70";
}

.icon_error-oct_alt:before {
	content: "\71";
}

.icon_error-circle_alt:before {
	content: "\72";
}

.icon_error-triangle_alt:before {
	content: "\73";
}

.icon_question_alt2:before {
	content: "\74";
}

.icon_question:before {
	content: "\75";
}

.icon_comment_alt:before {
	content: "\76";
}

.icon_chat_alt:before {
	content: "\77";
}

.icon_vol-mute_alt:before {
	content: "\78";
}

.icon_volume-low_alt:before {
	content: "\79";
}

.icon_volume-high_alt:before {
	content: "\7a";
}

.icon_quotations:before {
	content: "\7b";
}

.icon_quotations_alt2:before {
	content: "\7c";
}

.icon_clock_alt:before {
	content: "\7d";
}

.icon_lock_alt:before {
	content: "\7e";
}

.icon_lock-open_alt:before {
	content: "\e000";
}

.icon_key_alt:before {
	content: "\e001";
}

.icon_cloud_alt:before {
	content: "\e002";
}

.icon_cloud-upload_alt:before {
	content: "\e003";
}

.icon_cloud-download_alt:before {
	content: "\e004";
}

.icon_image:before {
	content: "\e005";
}

.icon_images:before {
	content: "\e006";
}

.icon_lightbulb_alt:before {
	content: "\e007";
}

.icon_gift_alt:before {
	content: "\e008";
}

.icon_house_alt:before {
	content: "\e009";
}

.icon_genius:before {
	content: "\e00a";
}

.icon_mobile:before {
	content: "\e00b";
}

.icon_tablet:before {
	content: "\e00c";
}

.icon_laptop:before {
	content: "\e00d";
}

.icon_desktop:before {
	content: "\e00e";
}

.icon_camera_alt:before {
	content: "\e00f";
}

.icon_mail_alt:before {
	content: "\e010";
}

.icon_cone_alt:before {
	content: "\e011";
}

.icon_ribbon_alt:before {
	content: "\e012";
}

.icon_bag_alt:before {
	content: "\e013";
}

.icon_creditcard:before {
	content: "\e014";
}

.icon_cart_alt:before {
	content: "\e015";
}

.icon_paperclip:before {
	content: "\e016";
}

.icon_tag_alt:before {
	content: "\e017";
}

.icon_tags_alt:before {
	content: "\e018";
}

.icon_trash_alt:before {
	content: "\e019";
}

.icon_cursor_alt:before {
	content: "\e01a";
}

.icon_mic_alt:before {
	content: "\e01b";
}

.icon_compass_alt:before {
	content: "\e01c";
}

.icon_pin_alt:before {
	content: "\e01d";
}

.icon_pushpin_alt:before {
	content: "\e01e";
}

.icon_map_alt:before {
	content: "\e01f";
}

.icon_drawer_alt:before {
	content: "\e020";
}

.icon_toolbox_alt:before {
	content: "\e021";
}

.icon_book_alt:before {
	content: "\e022";
}

.icon_calendar:before {
	content: "\e023";
}

.icon_film:before {
	content: "\e024";
}

.icon_table:before {
	content: "\e025";
}

.icon_contacts_alt:before {
	content: "\e026";
}

.icon_headphones:before {
	content: "\e027";
}

.icon_lifesaver:before {
	content: "\e028";
}

.icon_piechart:before {
	content: "\e029";
}

.icon_refresh:before {
	content: "\e02a";
}

.icon_link_alt:before {
	content: "\e02b";
}

.icon_link:before {
	content: "\e02c";
}

.icon_loading:before {
	content: "\e02d";
}

.icon_blocked:before {
	content: "\e02e";
}

.icon_archive_alt:before {
	content: "\e02f";
}

.icon_heart_alt:before {
	content: "\e030";
}

.icon_star_alt:before {
	content: "\e031";
}

.icon_star-half_alt:before {
	content: "\e032";
}

.icon_star:before {
	content: "\e033";
}

.icon_star-half:before {
	content: "\e034";
}

.icon_tools:before {
	content: "\e035";
}

.icon_tool:before {
	content: "\e036";
}

.icon_cog:before {
	content: "\e037";
}

.icon_cogs:before {
	content: "\e038";
}

.arrow_up_alt:before {
	content: "\e039";
}

.arrow_down_alt:before {
	content: "\e03a";
}

.arrow_left_alt:before {
	content: "\e03b";
}

.arrow_right_alt:before {
	content: "\e03c";
}

.arrow_left-up_alt:before {
	content: "\e03d";
}

.arrow_right-up_alt:before {
	content: "\e03e";
}

.arrow_right-down_alt:before {
	content: "\e03f";
}

.arrow_left-down_alt:before {
	content: "\e040";
}

.arrow_condense_alt:before {
	content: "\e041";
}

.arrow_expand_alt3:before {
	content: "\e042";
}

.arrow_carrot_up_alt:before {
	content: "\e043";
}

.arrow_carrot-down_alt:before {
	content: "\e044";
}

.arrow_carrot-left_alt:before {
	content: "\e045";
}

.arrow_carrot-right_alt:before {
	content: "\e046";
}

.arrow_carrot-2up_alt:before {
	content: "\e047";
}

.arrow_carrot-2dwnn_alt:before {
	content: "\e048";
}

.arrow_carrot-2left_alt:before {
	content: "\e049";
}

.arrow_carrot-2right_alt:before {
	content: "\e04a";
}

.arrow_triangle-up_alt:before {
	content: "\e04b";
}

.arrow_triangle-down_alt:before {
	content: "\e04c";
}

.arrow_triangle-left_alt:before {
	content: "\e04d";
}

.arrow_triangle-right_alt:before {
	content: "\e04e";
}

.icon_minus_alt:before {
	content: "\e04f";
}

.icon_plus_alt:before {
	content: "\e050";
}

.icon_close_alt:before {
	content: "\e051";
}

.icon_check_alt:before {
	content: "\e052";
}

.icon_zoom-out:before {
	content: "\e053";
}

.icon_zoom-in:before {
	content: "\e054";
}

.icon_stop_alt:before {
	content: "\e055";
}

.icon_menu-square_alt:before {
	content: "\e056";
}

.icon_menu-circle_alt:before {
	content: "\e057";
}

.icon_document:before {
	content: "\e058";
}

.icon_documents:before {
	content: "\e059";
}

.icon_pencil_alt:before {
	content: "\e05a";
}

.icon_folder:before {
	content: "\e05b";
}

.icon_folder-open:before {
	content: "\e05c";
}

.icon_folder-add:before {
	content: "\e05d";
}

.icon_folder_upload:before {
	content: "\e05e";
}

.icon_folder_download:before {
	content: "\e05f";
}

.icon_info:before {
	content: "\e060";
}

.icon_error-circle:before {
	content: "\e061";
}

.icon_error-oct:before {
	content: "\e062";
}

.icon_error-triangle:before {
	content: "\e063";
}

.icon_question_alt:before {
	content: "\e064";
}

.icon_comment:before {
	content: "\e065";
}

.icon_chat:before {
	content: "\e066";
}

.icon_vol-mute:before {
	content: "\e067";
}

.icon_volume-low:before {
	content: "\e068";
}

.icon_volume-high:before {
	content: "\e069";
}

.icon_quotations_alt:before {
	content: "\e06a";
}

.icon_clock:before {
	content: "\e06b";
}

.icon_lock:before {
	content: "\e06c";
}

.icon_lock-open:before {
	content: "\e06d";
}

.icon_key:before {
	content: "\e06e";
}

.icon_cloud:before {
	content: "\e06f";
}

.icon_cloud-upload:before {
	content: "\e070";
}

.icon_cloud-download:before {
	content: "\e071";
}

.icon_lightbulb:before {
	content: "\e072";
}

.icon_gift:before {
	content: "\e073";
}

.icon_house:before {
	content: "\e074";
}

.icon_camera:before {
	content: "\e075";
}

.icon_mail:before {
	content: "\e076";
}

.icon_cone:before {
	content: "\e077";
}

.icon_ribbon:before {
	content: "\e078";
}

.icon_bag:before {
	content: "\e079";
}

.icon_cart:before {
	content: "\e07a";
}

.icon_tag:before {
	content: "\e07b";
}

.icon_tags:before {
	content: "\e07c";
}

.icon_trash:before {
	content: "\e07d";
}

.icon_cursor:before {
	content: "\e07e";
}

.icon_mic:before {
	content: "\e07f";
}

.icon_compass:before {
	content: "\e080";
}

.icon_pin:before {
	content: "\e081";
}

.icon_pushpin:before {
	content: "\e082";
}

.icon_map:before {
	content: "\e083";
}

.icon_drawer:before {
	content: "\e084";
}

.icon_toolbox:before {
	content: "\e085";
}

.icon_book:before {
	content: "\e086";
}

.icon_contacts:before {
	content: "\e087";
}

.icon_archive:before {
	content: "\e088";
}

.icon_heart:before {
	content: "\e089";
}

.icon_profile:before {
	content: "\e08a";
}

.icon_group:before {
	content: "\e08b";
}

.icon_grid-2x2:before {
	content: "\e08c";
}

.icon_grid-3x3:before {
	content: "\e08d";
}

.icon_music:before {
	content: "\e08e";
}

.icon_pause_alt:before {
	content: "\e08f";
}

.icon_phone:before {
	content: "\e090";
}

.icon_upload:before {
	content: "\e091";
}

.icon_download:before {
	content: "\e092";
}

.social_facebook:before {
	content: "\e093";
}

.social_twitter:before {
	content: "\e094";
}

.social_pinterest:before {
	content: "\e095";
}

.social_googleplus:before {
	content: "\e096";
}

.social_tumblr:before {
	content: "\e097";
}

.social_tumbleupon:before {
	content: "\e098";
}

.social_wordpress:before {
	content: "\e099";
}

.social_instagram:before {
	content: "\e09a";
}

.social_dribbble:before {
	content: "\e09b";
}

.social_vimeo:before {
	content: "\e09c";
}

.social_linkedin:before {
	content: "\e09d";
}

.social_rss:before {
	content: "\e09e";
}

.social_deviantart:before {
	content: "\e09f";
}

.social_share:before {
	content: "\e0a0";
}

.social_myspace:before {
	content: "\e0a1";
}

.social_skype:before {
	content: "\e0a2";
}

.social_youtube:before {
	content: "\e0a3";
}

.social_picassa:before {
	content: "\e0a4";
}

.social_googledrive:before {
	content: "\e0a5";
}

.social_flickr:before {
	content: "\e0a6";
}

.social_blogger:before {
	content: "\e0a7";
}

.social_spotify:before {
	content: "\e0a8";
}

.social_delicious:before {
	content: "\e0a9";
}

.social_facebook_circle:before {
	content: "\e0aa";
}

.social_twitter_circle:before {
	content: "\e0ab";
}

.social_pinterest_circle:before {
	content: "\e0ac";
}

.social_googleplus_circle:before {
	content: "\e0ad";
}

.social_tumblr_circle:before {
	content: "\e0ae";
}

.social_stumbleupon_circle:before {
	content: "\e0af";
}

.social_wordpress_circle:before {
	content: "\e0b0";
}

.social_instagram_circle:before {
	content: "\e0b1";
}

.social_dribbble_circle:before {
	content: "\e0b2";
}

.social_vimeo_circle:before {
	content: "\e0b3";
}

.social_linkedin_circle:before {
	content: "\e0b4";
}

.social_rss_circle:before {
	content: "\e0b5";
}

.social_deviantart_circle:before {
	content: "\e0b6";
}

.social_share_circle:before {
	content: "\e0b7";
}

.social_myspace_circle:before {
	content: "\e0b8";
}

.social_skype_circle:before {
	content: "\e0b9";
}

.social_youtube_circle:before {
	content: "\e0ba";
}

.social_picassa_circle:before {
	content: "\e0bb";
}

.social_googledrive_alt2:before {
	content: "\e0bc";
}

.social_flickr_circle:before {
	content: "\e0bd";
}

.social_blogger_circle:before {
	content: "\e0be";
}

.social_spotify_circle:before {
	content: "\e0bf";
}

.social_delicious_circle:before {
	content: "\e0c0";
}

.social_facebook_square:before {
	content: "\e0c1";
}

.social_twitter_square:before {
	content: "\e0c2";
}

.social_pinterest_square:before {
	content: "\e0c3";
}

.social_googleplus_square:before {
	content: "\e0c4";
}

.social_tumblr_square:before {
	content: "\e0c5";
}

.social_stumbleupon_square:before {
	content: "\e0c6";
}

.social_wordpress_square:before {
	content: "\e0c7";
}

.social_instagram_square:before {
	content: "\e0c8";
}

.social_dribbble_square:before {
	content: "\e0c9";
}

.social_vimeo_square:before {
	content: "\e0ca";
}

.social_linkedin_square:before {
	content: "\e0cb";
}

.social_rss_square:before {
	content: "\e0cc";
}

.social_deviantart_square:before {
	content: "\e0cd";
}

.social_share_square:before {
	content: "\e0ce";
}

.social_myspace_square:before {
	content: "\e0cf";
}

.social_skype_square:before {
	content: "\e0d0";
}

.social_youtube_square:before {
	content: "\e0d1";
}

.social_picassa_square:before {
	content: "\e0d2";
}

.social_googledrive_square:before {
	content: "\e0d3";
}

.social_flickr_square:before {
	content: "\e0d4";
}

.social_blogger_square:before {
	content: "\e0d5";
}

.social_spotify_square:before {
	content: "\e0d6";
}

.social_delicious_square:before {
	content: "\e0d7";
}

.icon_printer:before {
	content: "\e103";
}

.icon_calulator:before {
	content: "\e0ee";
}

.icon_building:before {
	content: "\e0ef";
}

.icon_floppy:before {
	content: "\e0e8";
}

.icon_drive:before {
	content: "\e0ea";
}

.icon_search-2:before {
	content: "\e101";
}

.icon_id:before {
	content: "\e107";
}

.icon_id-2:before {
	content: "\e108";
}

.icon_puzzle:before {
	content: "\e102";
}

.icon_like:before {
	content: "\e106";
}

.icon_dislike:before {
	content: "\e0eb";
}

.icon_mug:before {
	content: "\e105";
}

.icon_currency:before {
	content: "\e0ed";
}

.icon_wallet:before {
	content: "\e100";
}

.icon_pens:before {
	content: "\e104";
}

.icon_easel:before {
	content: "\e0e9";
}

.icon_flowchart:before {
	content: "\e109";
}

.icon_datareport:before {
	content: "\e0ec";
}

.icon_briefcase:before {
	content: "\e0fe";
}

.icon_shield:before {
	content: "\e0f6";
}

.icon_percent:before {
	content: "\e0fb";
}

.icon_globe:before {
	content: "\e0e2";
}

.icon_globe-2:before {
	content: "\e0e3";
}

.icon_target:before {
	content: "\e0f5";
}

.icon_hourglass:before {
	content: "\e0e1";
}

.icon_balance:before {
	content: "\e0ff";
}

.icon_rook:before {
	content: "\e0f8";
}

.icon_printer-alt:before {
	content: "\e0fa";
}

.icon_calculator_alt:before {
	content: "\e0e7";
}

.icon_building_alt:before {
	content: "\e0fd";
}

.icon_floppy_alt:before {
	content: "\e0e4";
}

.icon_drive_alt:before {
	content: "\e0e5";
}

.icon_search_alt:before {
	content: "\e0f7";
}

.icon_id_alt:before {
	content: "\e0e0";
}

.icon_id-2_alt:before {
	content: "\e0fc";
}

.icon_puzzle_alt:before {
	content: "\e0f9";
}

.icon_like_alt:before {
	content: "\e0dd";
}

.icon_dislike_alt:before {
	content: "\e0f1";
}

.icon_mug_alt:before {
	content: "\e0dc";
}

.icon_currency_alt:before {
	content: "\e0f3";
}

.icon_wallet_alt:before {
	content: "\e0d8";
}

.icon_pens_alt:before {
	content: "\e0db";
}

.icon_easel_alt:before {
	content: "\e0f0";
}

.icon_flowchart_alt:before {
	content: "\e0df";
}

.icon_datareport_alt:before {
	content: "\e0f2";
}

.icon_briefcase_alt:before {
	content: "\e0f4";
}

.icon_shield_alt:before {
	content: "\e0d9";
}

.icon_percent_alt:before {
	content: "\e0da";
}

.icon_globe_alt:before {
	content: "\e0de";
}

.icon_clipboard:before {
	content: "\e0e6";
}


.glyph {
	float: left;
	text-align: center;
	padding: .75em;
	margin: .4em 1.5em .75em 0;
	width: 6em;
	text-shadow: none;
}

.glyph_big {
	font-size: 128px;
	color: #59c5dc;
	float: left;
	margin-right: 20px;
}

.glyph div {
	padding-bottom: 10px;
}

.glyph input {
	font-family: consolas, monospace;
	font-size: 12px;
	width: 100%;
	text-align: center;
	border: 0;
	box-shadow: 0 0 0 1px #ccc;
	padding: .2em;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
}

.centered {
	margin-left: auto;
	margin-right: auto;
}

.glyph .fs1 {
	font-size: 2em;
}